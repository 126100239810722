import {ProcessThroughput} from "../domain/common.ts";
import {ConfigurationPopupFormSpecification} from "../components/common/popup/common.ts";


//TODO TK maybe we can remove this mapping and just truncate the gltf when loading the files and set such string as id of primitive
export const filenameToZoneComponentId: Record<string, Record<string, string>> = {
    security: {
        "belt_left.gltf": "belt_left",
        "belt_right.gltf": "belt_right",
        "clear_lane_left.gltf": "clear_lane_left",
        "clear_lane_right.gltf": "clear_lane_right",
        "decision_point_left.gltf": "decision_point_left", //TODO TK to what input it should be mapped?
        "decision_point_right.gltf": "decision_point_right", //TODO TK to what input is should be mapped?
        "divestment_stations_left.gltf": "divestment_stations_left",
        "divestment_stations_right.gltf": "divestment_stations_right",
        "reject_lane_left.gltf": "reject_left",
        "reject_lane_right.gltf": "reject_right",
        "reject_management_left.gltf": "reject_management_left",
        "reject_management_right.gltf": "reject_management_right",
        "tray_left.gltf": "tray_left",
        "tray_right.gltf": "tray_right",
        "xray_left.gltf": "x_ray_left",
        "xray_right.gltf": "x_ray_right",
        "bs.gltf": "bs",
        "body_search_male.gltf": "body_search_male",
        "body_search_female.gltf": "body_search_female",
        "wtmd.gltf": "wtmd",
        "redress.gltf": "re_pack"
    },
    passport_control: {
        "automate_gate.gltf": "automate_gate",
        "manual_podium.gltf": "manual_podium",
        "registration_kiosk.gltf": "registration_kiosk"
    }
};


export const inputToComponentId: Record<string, Record<string, string[]>> = {
    security: {
        "belt": ["belt_left", "belt_right"],
        "clear_lane": ["clear_lane_left", "clear_lane_right"],
        "divestment_stations": ["divestment_stations_left", "divestment_stations_right"],
        "reject": ["reject_left", "reject_right", "reject_management_left", "reject_management_right"],
        "tray": ["tray_left", "tray_right"],
        "x_ray": ["x_ray_left", "x_ray_right"],
        "body_scanner": ["bs", "wtmd"],
        "body_search": ["body_search_male", "body_search_female"],
        "re_pack": ["re_pack"]
    },
    passport_control: {
        "automate_gate": ["automate_gate"],
        "manual_podium": ["manual_podium"],
        "registration_kiosk": ["registration_kiosk"]
    }
};

export const componentToInputId: Record<string, Record<string, string>> = {};

Object.keys(inputToComponentId).forEach(zone => {
    componentToInputId[zone] = {};
    Object.entries(inputToComponentId[zone]).forEach(([inputId, componentIds]) => {
        componentIds.forEach(componentId => {
            componentToInputId[zone][componentId] = inputId;
        });
    });
});


export const inputToSubprocessesId: Record<string, Record<string, string[]>> = {
    security: {
        "divestment_stations": ["divestment_stations"],
        "tray": ["recirculated_trays"],
        "belt": ["belt"],
        "x_ray": ["x_ray"],
        "clear_lane": ["clear_lane"],
        "reject": ["reject_lane", "reject_stations"],
        "body_scanner": ["body_scanner"],
        "body_search": ["body_search_female", "body_search_male"],
        "re_pack": ["re_pack"]
    },
    passport_control: {
        "automate_gate": ["automate_gate"],
        "manual_podium": ["manual_podium"],
        "registration_kiosk": ["registration_kiosk"]
    }
};


export const inputSpecification: Record<string, ConfigurationPopupFormSpecification[]> = {
    security: [
        {
            componentId: "divestment_stations",
            displayName: "Divestment",
            parameters: [
                { id: "service_time_per_tray", displayName: "Average service time per tray [s]", value: 45, type: "number" },
                { id: "divestment_positions", displayName: "Number of divestment positions", value: 6, type: "number" }
            ]

        },
        {
            componentId: "tray",
            displayName: "Tray",
            parameters: [
                { id: "length", displayName: "Length [m]", value: 0.55, type: "number" },
                { id: "gap", displayName: "Gap [m]", value: 0.18, type: "number" },
                { id: "trays_per_passenger", displayName: "Trays per passenger", value: 1.3, type: "number" }
            ]
        },
        {
            componentId: "belt",
            displayName: "Belt",
            parameters: [
                { id: "speed", displayName: "Speed [m/s]", value: 0.17, type: "number" }
            ]
        },
        {
            componentId: "x_ray",
            displayName: "X-Ray",
            parameters: [
                { id: "belt_length", displayName: "Belt length [m]", value: 3, type: "number" },
                { id: "screening_time", displayName: "Screening time [s]", value: 18, type: "number" }
            ]
        },
        {
            componentId: "clear_lane",
            displayName: "Clear lane",
            parameters: [
                { id: "on_lane_repack_percent", displayName: "On lane re-pack [%]", value: 80, type: "percentage" },
                { id: "on_lane_repack_time", displayName: "On lane re-pack time [s]", value: 42, type: "number" },
                { id: "pickup_to_off_lane_repack_time", displayName: "Pick-up to off-lane re-pack time [s]", value: 21, type: "number" },
                { id: "clear_lane_capacity", displayName: "Clear lane capacity", value: 6, type: "number" }
            ]
        },
        {
            componentId: "reject",
            displayName: "Reject",
            parameters: [
                { id: "tray_reject_rate", displayName: "Tray reject rate [%]", value: 14, type: "percentage" },
                { id: "tray_search_time", displayName: "Tray search time [s]", value: 60, type: "number" },
                { id: "max_passenger_waiting_time", displayName: "Max passenger waiting time [min]", value: 5, type: "number" },
                { id: "reject_stations", displayName: "Reject stations", value: 2, type: "number" },
                { id: "reject_lane_capacity", displayName: "Reject lane capacity", value: 5, type: "number" }
            ]
        },
        {
            componentId: "body_scanner",
            displayName: "Body scanner",
            parameters: [
                { id: "scanning_rate", displayName: "Body scanning rate [%]", value: 100, type: "percentage" },
                { id: "scanning_service_time", displayName: "Body scanning service time [s]", value: 8, type: "number" },
                { id: "number_of_scanners", displayName: "Number of body scanners", value: 3, type: "number" }
            ]
        },
        {
            componentId: "body_search",
            displayName: "Body search",
            parameters: [
                { id: "alarm_rate", displayName: "Alarm rate [%]", value: 20, type: "percentage" },
                { id: "male_proportion", displayName: "Male proportion [%]", value: 50, type: "percentage" },
                { id: "service_time", displayName: "Service time [s]", value: 55, type: "number" },
                { id: "female_agents", displayName: "Female agents", value: 2, type: "number" },
                { id: "male_agents", displayName: "Male agents", value: 2, type: "number" }
            ]
        },
        {
            componentId: "re_pack",
            displayName: "Re-pack",
            parameters: [
                { id: "off_lane_repack_time", displayName: "Off lane re-pack time [s]", value: 75, type: "number" },
                { id: "off_lane_repack_stations", displayName: "Off lane re-pack stations", value: 8, type: "number" }
            ]
        }
    ],
    passport_control: [
        {
            componentId: "automate_gate",
            displayName: "Automate Gate",
            parameters: [
                {id: "service_time_per_passenger", displayName: "Service time per passenger [s]", value: 20},
                {id: "number_of_gates", displayName: "Number of gates", value: 5}
            ]
        },
        {
            componentId: "manual_podium",
            displayName: "Manual Podium",
            parameters: [
                {id: "service_time_per_passenger", displayName: "Service time per passenger [s]", value: 60},
                {id: "number_of_podiums", displayName: "Number of podiums", value: 3}
            ]
        },
        {
            componentId: "registration_kiosk",
            displayName: "Registration Kiosk",
            parameters: [
                {id: "service_time_per_passenger", displayName: "Service time per passenger [s]", value: 30},
                {id: "number_of_kiosks", displayName: "Number of kiosks", value: 4}
            ]
        }
    ]
};

export const peakHourPassengerDemandSpecification: ConfigurationPopupFormSpecification =  {
    componentId: "peak_hour_passenger_demand",
    displayName: "Demand",
    parameters: [
        {id: "value", displayName: "Value", value: 5000},
    ]
}


export const subprocessThroughput: Record<string, ProcessThroughput[]> = {
    security: [
        {
            id: "belt",
            displayName: "Belt",
            paxPerHourPerLane: 0,
            traysPerHourPerLane:0,
            paxPotential: 0,
            traysPotential: 0,
            isBottleneck: false
        },
        {
            id: "divestment_stations",
            displayName: "Divestment stations",
            paxPerHourPerLane: 0,
            traysPerHourPerLane:0,
            paxPotential: 0,
            traysPotential: 0,
            isBottleneck: false
        },
        {
            id: "x_ray",
            displayName: "X-Ray",
            paxPerHourPerLane: 0,
            traysPerHourPerLane:0,
            paxPotential: 0,
            traysPotential: 0,
            isBottleneck: false
        },
        {
            id: "recirculated_trays",
            displayName: "Recirculated trays",
            paxPerHourPerLane: 0,
            traysPerHourPerLane:0,
            paxPotential: 0,
            traysPotential: 0,
            isBottleneck: false
        },
        {
            id: "body_scanner",
            displayName: "Body scanner",
            paxPerHourPerLane: 0,
            traysPerHourPerLane:0,
            paxPotential: 0,
            traysPotential: 0,
            isBottleneck: false
        },
        {
            id: "body_search_female",
            displayName: "Body search female",
            paxPerHourPerLane: 0,
            traysPerHourPerLane:0,
            paxPotential: 0,
            traysPotential: 0,
            isBottleneck: false
        },
        {
            id: "body_search_male",
            displayName: "Body search male",
            paxPerHourPerLane: 0,
            traysPerHourPerLane:0,
            paxPotential: 0,
            traysPotential: 0,
            isBottleneck: false
        },
        {
            id: "reject_lane",
            displayName: "Reject lane",
            paxPerHourPerLane: 0,
            traysPerHourPerLane:0,
            paxPotential: 0,
            traysPotential: 0,
            isBottleneck: false
        },
        {
            id: "reject_stations",
            displayName: "Reject stations",
            paxPerHourPerLane: 0,
            traysPerHourPerLane:0,
            paxPotential: 0,
            traysPotential: 0,
            isBottleneck: false
        },
        {
            id: "clear_lane",
            displayName: "Clear lane",
            paxPerHourPerLane: 0,
            traysPerHourPerLane:0,
            paxPotential: 0,
            traysPotential: 0,
            isBottleneck: false
        },
        {
            id: "re_pack",
            displayName: "Re-pack",
            paxPerHourPerLane: 0,
            traysPerHourPerLane:0,
            paxPotential: 0,
            traysPotential: 0,
            isBottleneck: false
        }
    ],
    passport_control: [
        {
            id: "automate_gate",
            displayName: "Automate Gate",
            paxPerHourPerLane: 0,
            traysPerHourPerLane:0,
            paxPotential: 0,
            traysPotential: 0,
            isBottleneck: false
        },
        {
            id: "manual_podium",
            displayName: "Manual Podium",
            paxPerHourPerLane: 0,
            traysPerHourPerLane:0,
            paxPotential: 0,
            traysPotential: 0,
            isBottleneck: false
        },
        {
            id: "registration_kiosk",
            displayName: "Registration Kiosk",
            paxPerHourPerLane: 0,
            traysPerHourPerLane:0,
            paxPotential: 0,
            traysPotential: 0,
            isBottleneck: false
        }
    ]
};


export const isEditableEdgeLabelEnabled = false;