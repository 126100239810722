import { useState } from 'react';
import './WizardView.css';

function WizardView({
                        onClose,
                        onFinish
                    }: {
    onClose: () => void,
    onFinish: () => void
}) {
    const [step, setStep] = useState(1);
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [selectedOptionsStepOne, setSelectedOptionsStepOne] = useState<string[]>([]);
    const [selectedOptionsStepTwo, setSelectedOptionsStepTwo] = useState<string[]>([]);

    const nextStep = () => {
        setStep(prev => prev + 1);
    };

    const prevStep = () => {
        setStep(prev => prev - 1);
    };

    return (
        <div className="wizard-overlay">
            <div className="wizard-container">
                <div className="wizard-header">
                    <h1>{projectName || 'New Project'}</h1>
                    <button onClick={onClose} className="wizard-close-button wizard-button">&times;</button>
                </div>
                <div className="wizard-content">
                    <div>
                        {step === 1 && (
                            <ProjectDetailsStep
                                projectName={projectName}
                                setProjectName={setProjectName}
                                projectDescription={projectDescription}
                                setProjectDescription={setProjectDescription}
                            />
                        )}
                        {step === 2 && (
                            <StepOne
                                selectedOptions={selectedOptionsStepOne}
                                setSelectedOptions={setSelectedOptionsStepOne}
                            />
                        )}
                        {step === 3 && (
                            <StepTwo
                                selectedOptions={selectedOptionsStepTwo}
                                setSelectedOptions={setSelectedOptionsStepTwo}
                            />
                        )}
                        {step === 4 && <StepThree />}
                    </div>
                </div>
                <div className="wizard-footer">
                    {step > 1 && <button onClick={prevStep} className="wizard-button">Back</button>}
                    {step < 4 && <button onClick={nextStep} className="wizard-button">Next</button>}
                    {step === 4 && <button onClick={onFinish} className="wizard-button">Finish</button>}
                </div>
            </div>
        </div>
    );
}


function ProjectDetailsStep({
                                projectName,
                                setProjectName,
                                projectDescription,
                                setProjectDescription
                            }: {
    projectName: string,
    setProjectName: (name: string) => void,
    projectDescription: string,
    setProjectDescription: (description: string) => void
}) {
    return (
        <div>
            <h3>Project Details</h3>
            <div className="form-group">
                <label htmlFor="project-name">Project Name</label>
                <input
                    type="text"
                    id="project-name"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    placeholder="Enter your project name"
                />
            </div>
            <div className="form-group">
                <label htmlFor="project-description">Project Description</label>
                <textarea
                    id="project-description"
                    value={projectDescription}
                    onChange={(e) => setProjectDescription(e.target.value)}
                    placeholder="Enter your project description"
                />
            </div>
        </div>
    );
}

function StepOne({ selectedOptions, setSelectedOptions }: { selectedOptions: string[], setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>> }) {

    const handleSelect = (option: string) => {
        setSelectedOptions((prevSelectedOptions: string[]) =>
            prevSelectedOptions.includes(option)
                ? prevSelectedOptions.filter((item: string) => item !== option) // Deselect if already selected
                : [...prevSelectedOptions, option] // Select if not already selected
        );
    };

    return (
        <div>
            <h3>What would you like to model</h3>
            <div className="button-group">
                <button
                    className={`select-button ${selectedOptions.includes('Terminal') ? 'selected' : ''} wizard-button`}
                    onClick={() => handleSelect('Terminal')}
                >
                    Terminal
                </button>
                <button
                    className={`select-button ${selectedOptions.includes('Landside') ? 'selected' : ''} wizard-button`}
                    onClick={() => handleSelect('Landside')}
                >
                    Landside
                </button>
                <button
                    className={`select-button ${selectedOptions.includes('Airside') ? 'selected' : ''} wizard-button`}
                    onClick={() => handleSelect('Airside')}
                >
                    Airside
                </button>
            </div>
        </div>
    );
}

function StepTwo({ selectedOptions, setSelectedOptions }: { selectedOptions: string[], setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>> }) {

    const handleSelect = (option: string) => {
        setSelectedOptions((prevSelectedOptions: string[]) =>
            prevSelectedOptions.includes(option)
                ? prevSelectedOptions.filter((item: string) => item !== option) // Deselect if already selected
                : [...prevSelectedOptions, option] // Select if not already selected
        );
    };

    return (
        <div>
            <h3>TERMINAL: What processes would you like to model?</h3>
            <div className="button-group">
                <button
                    className={`select-button ${selectedOptions.includes('Check-in') ? 'selected' : ''} wizard-button`}
                    onClick={() => handleSelect('Check-in')}
                >
                    Check-in
                </button>
                <button
                    className={`select-button ${selectedOptions.includes('Security') ? 'selected' : ''} wizard-button`}
                    onClick={() => handleSelect('Security')}
                >
                    Security
                </button>
                <button
                    className={`select-button ${selectedOptions.includes('Emigration') ? 'selected' : ''} wizard-button`}
                    onClick={() => handleSelect('Emigration')}
                >
                    Emigration
                </button>
                <button
                    className={`select-button ${selectedOptions.includes('Departure Lounge') ? 'selected' : ''} wizard-button`}
                    onClick={() => handleSelect('Departure Lounge')}
                >
                    Departure Lounge
                </button>
                <button
                    className={`select-button ${selectedOptions.includes('Departure Gates') ? 'selected' : ''} wizard-button`}
                    onClick={() => handleSelect('Departure Gates')}
                >
                    Departure Gates
                </button>
                <button
                    className={`select-button ${selectedOptions.includes('Arrival Gates') ? 'selected' : ''} wizard-button`}
                    onClick={() => handleSelect('Arrival Gates')}
                >
                    Arrival Gates
                </button>
                <button
                    className={`select-button ${selectedOptions.includes('Immigration') ? 'selected' : ''} wizard-button`}
                    onClick={() => handleSelect('Immigration')}
                >
                    Immigration
                </button>
                <button
                    className={`select-button ${selectedOptions.includes('Reclaim') ? 'selected' : ''} wizard-button`}
                    onClick={() => handleSelect('Reclaim')}
                >
                    Reclaim
                </button>
                <button
                    className={`select-button ${selectedOptions.includes('Customs') ? 'selected' : ''} wizard-button`}
                    onClick={() => handleSelect('Customs')}
                >
                    Customs
                </button>
            </div>
        </div>
    );
}

function StepThree() {
    return (
        <div>
            <h3>Step 3</h3>
            <p>Content for Step 3</p>
        </div>
    );
}

export default WizardView;
