

export interface DndPaxLabelProps {
    label: string,
    position: number

}

export function DndPaxLabel({label, position}: DndPaxLabelProps) {

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: `${position}px`,  // relies on the line but should be adjusted to stay in the center
                zIndex: 1001, //react flow elements default to 1000
                color: 'white',
                pointerEvents: 'none' // This is important to prevent interfering with draggable elements

            }}
        >
            <h1 style={{
                textDecoration: 'underline'
            }}> {label} </h1>
        </div>
    )

}