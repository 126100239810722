import InnerBox from "./InnerBox.tsx";
import OuterBox from "./OuterBox.tsx";
import { Suspense } from "react";
import { useLoader } from "@react-three/fiber";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import { Mesh, Object3D } from "three";
import { TerminalPartData } from "./AirportOverviewSidebar.tsx";

function AirbusModel() {
    // Load the FBX model using the useLoader hook
    const fbx = useLoader(FBXLoader, 'airbus-3d-model.fbx');

    // Scale the model down to 0.004 of its original size
    fbx.scale.set(0.004, 0.004, 0.004);

    fbx.position.set(15, 0, -30);

    return <primitive object={fbx} />;
}

export interface AirportModelProps {
    parts: TerminalPartData[];
    onHover: (id: number, isHovered: boolean) => void;
}

function AirportModel(props: AirportModelProps) {
    const {  parts, onHover } = props;


    // Load the FBX model using the useLoader hook
    const fbx = useLoader(FBXLoader, 'airport-3d-model.fbx');

    const desiredOpacity = 0.7;

    // Traverse the model and set opacity
    fbx.traverse((child: Object3D) => {
        if ((child as Mesh).isMesh) {
            const mesh = child as Mesh;

            // Check if the mesh has a material
            if (mesh.material) {
                // Handle multiple materials case
                if (Array.isArray(mesh.material)) {
                    mesh.material.forEach((mat) => {
                        if (mat) {
                            mat.transparent = true;    // Enable transparency
                            mat.opacity = desiredOpacity;          // Adjust opacity
                        }
                    });
                } else {
                    // Single material case
                    mesh.material.transparent = true;
                    mesh.material.opacity = desiredOpacity;
                }
            }
        }
    });

    fbx.rotation.y = 50 * Math.PI / 180; // Original model is rotated

    const factor = 0.02;
    fbx.scale.set(factor, factor, factor);
    fbx.position.set(0, 0, 30);

    return (
        <group>
            <primitive object={fbx} />
            <OuterBox position={[0, 2.5, 35]} boxGeo={[70, 5, 40]} opacity={0.0}>
                {parts.map((part) => (
                    <InnerBox
                        key={part.id}
                        part={part}
                        redirectRoute={part.route}
                        tooltipText={part.name}
                        position={part.position}
                        boxGeo={part.boxGeo}
                        onHover={onHover}
                    />
                ))}
            </OuterBox>
        </group>
    );
}

export interface AirportOverviewProps {
    onHover: (id: number, isHovered: boolean) => void;
    parts: TerminalPartData[];
}

function AirportOverview({ onHover, parts }: AirportOverviewProps) {

    return (
        <>
            {/* Load the models with suspense */}
            <Suspense fallback={null}>
                <AirbusModel />
            </Suspense>

            <Suspense fallback={null}>
                <AirportModel
                    parts={parts}
                    onHover={onHover}
                />
            </Suspense>

            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} />
        </>
    );
}

export default AirportOverview;
