import React, { ReactNode } from 'react';

interface FloatingComponentProps {
    position: 'left' | 'right';
    topDistance: number; // Top distance as a percentage
    children: ReactNode; // Content to be displayed inside the component
    title: string;
}

const FloatingComponent: React.FC<FloatingComponentProps> = ({ position, topDistance, children, title }) => {
    // Determine the style based on the position and top distance
    const style: React.CSSProperties = {
        position: 'fixed',
        top: `${topDistance}%`,
        [position]: '10px', // Either 'left: 10px' or 'right: 10px' based on position prop
        zIndex: 6001,
        padding: '10px',
        backgroundColor: '#0D0D0D',
        color: 'white',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
    };

    return (
        <>
            <div style={style}>
                <h1 style={{textDecoration: 'underline'}}> {title} </h1>

                {children}
            </div>
        </>
    );
};

export default FloatingComponent;
