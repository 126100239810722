import {GLTF, GLTFLoader} from 'three/addons/loaders/GLTFLoader.js';
import {ReactNode} from 'react';
import {filenameToZoneComponentId} from "../config/Config.tsx";
import {Mesh, MeshStandardMaterial} from 'three';
import {ProcessThroughput} from "../domain/common.ts";


interface Manifest {
    gltfFiles: string[];
}

export default function loadGraphics(
    gltfLoader: GLTFLoader,
    manifestPath: string,
    setClickedComponentId: (name: string) => void,
    setHoveredComponentId: (componentId: string | null) => void,
    zone: string,
): Promise<ReactNode[]> {

    return fetch(manifestPath)
        .then((response) => response.json() as Promise<Manifest>)
        .then((data: Manifest) => {
            const {gltfFiles} = data;

            const elements = gltfFiles.map((filePath) => {
                return new Promise<ReactNode>((resolve, reject) => {
                    const fileNameWithoutPath = filePath.split('/').pop() as string;
                    const componentId = filenameToZoneComponentId[zone][fileNameWithoutPath];

                    gltfLoader.load(
                        filePath,
                        (gltf: GLTF) => {
                            const scene = gltf.scene;

                            // Traverse the scene and set user data for the component
                            scene.traverse((child) => {
                                if (child instanceof Mesh && child.material instanceof MeshStandardMaterial) {
                                    child.userData.componentId = componentId;  // Store component ID
                                    child.userData.originalEmissive = child.material.emissive.clone();  // Store original emissive color
                                }
                                // Required for shadow casting
                                child.castShadow = true;
                            });


                            const handlePointerOver = () => {
                                setHoveredComponentId(componentId);
                            }

                            const primitiveElement = (
                                <primitive
                                    key={componentId}
                                    object={scene}
                                    onPointerOver={handlePointerOver}
                                    onPointerOut={() => {
                                        setHoveredComponentId(null);  // Reset when pointer out
                                    }}
                                    onClick={() => {
                                        setClickedComponentId(componentId);
                                    }}
                                />
                            );
                            resolve(primitiveElement);
                        },
                        undefined,
                        (error: unknown) => {
                            console.error(`Error loading GLTF model from ${filePath}:`, error);
                            reject(error);
                        }
                    );
                });
            });

            return Promise.all(elements) as Promise<ReactNode[]>;
        })
        .catch((error) => {
            console.error('Error loading GLTF files:', error);
            throw error;
        });
}


//TODO TK let's set the bottleneck subprocess in parent and maybe calculate the input there too
export function isInputForBottleneckSubprocess(inputId: string,
                                               processThroughputsForZone: ProcessThroughput[],
                                               inputToSubprocessesIdForZone: Record<string, string[]>): boolean {

    const subprocesses = inputToSubprocessesIdForZone[inputId] || [];
    return subprocesses.some(subprocess => {
        const throughput = processThroughputsForZone.find(item => item.id === subprocess);
        console.log(throughput);
        return throughput?.isBottleneck;
    });
};