import {useRef} from "react";
import {Mesh} from "three";
import {useNavigate} from "react-router-dom";
import SimpleTooltipSprite from "./SimpleTooltipSprite.tsx";
import {TerminalPartData} from "./AirportOverviewSidebar.tsx";

export interface InnerBoxProps {
    redirectRoute: string
    tooltipText: string
    position: [number, number, number]
    boxGeo: [number, number, number]
    onHover: (id: number, isHovered: boolean) => void
    part: TerminalPartData
}

function InnerBox(properties: InnerBoxProps) {

    const innerCubeRef = useRef<Mesh>(null);

    const navigate = useNavigate();


    const handleInnerCubeClick = (route: string) => {
        navigate(route);
    };

    const tooltipPosition: [number, number, number] = [properties.position[0], properties.position[1] + 20, properties.position[2]]

    const isThisHovered = properties.part.isHovered

    return (
        <>

                <group
                    onPointerOver={function (event) {
                        properties.onHover(properties.part.id, true);
                        return event.stopPropagation();
                    }}
                    onPointerOut={() => {
                        properties.onHover(properties.part.id, false);
                    }}
                    onClick={() => handleInnerCubeClick(properties.redirectRoute)} // Handle click on the group
                >
                    <mesh
                        ref={innerCubeRef}
                        position={properties.position}
                    >
                        <boxGeometry args={properties.boxGeo} />
                        <meshStandardMaterial color={isThisHovered ? 'red' : 'black'} />
                    </mesh>
                    <SimpleTooltipSprite tooltipText={properties.tooltipText} isThisTooltipHovered={isThisHovered} position={tooltipPosition}/>


                </group>
        </>
    )
}

export default InnerBox;