function InfinityFloor() {
    const size = 10000;

    const planeSize = 10000;
    const divisions = 10000; // Adjust the number of divisions to make the grid more or less dense
    const gridColor = 0x3D3D3D; // color of the grid
    const gridColorCenterLine = gridColor


    return (
        <>
            {/*  Mesh positioned at 0.1 to avoid colision with grid */}
            <mesh receiveShadow={true} castShadow rotation={[-Math.PI / 2, 0, 0]}
                  position={[0, -0.1, 0]}>
                <planeGeometry args={[size, size]}/>
                <meshStandardMaterial color="black"/>
            </mesh>
            <gridHelper args={[planeSize, divisions, gridColorCenterLine, gridColor]}>
            </gridHelper>
        </>
    );
}

export default InfinityFloor;

