import React, {useRef} from "react";
import {Mesh} from "three";

export interface OuterBoxProps {
    position: [number, number, number]
    boxGeo: [number, number, number]
    opacity: number,
    children?: React.ReactNode
}

export default function OuterBox(props: OuterBoxProps) {
    const outerCubeRef = useRef<Mesh>(null);

    return (
        <mesh
            ref={outerCubeRef}
            position={props.position}
        >
            <boxGeometry args={props.boxGeo}/>
            <meshStandardMaterial color="blue" transparent={true} opacity={props.opacity}/>

            {props.children}

        </mesh>
    )

}