import {
    BaseEdge, EdgeLabelRenderer,
    EdgeProps,
    getBezierPath,
} from '@xyflow/react';

export interface SmartEdgeProps extends EdgeProps {
    passengerRatio?: number; // Add optional passenger ratio prop
    onRatioChange?: (id: string, newRatio: number) => void; // Function to update ratio
    isTransferEdge?: boolean;
    isValid?: boolean;
}

export default function SmartEdge({
                                      id,
                                      sourceX,
                                      sourceY,
                                      targetX,
                                      targetY,
                                      sourcePosition,
                                      targetPosition,
                                      style = {},
                                      markerEnd,
                                      passengerRatio,
                                      isTransferEdge,
                                      isValid
                                  }: SmartEdgeProps) {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const invalidEdgeColor = '#7C0909';
    const conditionalStyleIfInvalid = isValid ? {} : {stroke: invalidEdgeColor, strokeWidth: 2};
    const labelColor = '#333333'
    const transferLabelColor = 'white'
    const edgeLabelBorderColor = isValid ? '#555555' : invalidEdgeColor

    let edgeStyle = {
        ...style,
        ...conditionalStyleIfInvalid
    };

    if (isTransferEdge) {
        const validTransferEdgeColor = '#0070C0';
        edgeStyle = {
            stroke: validTransferEdgeColor,
            strokeWidth: 2,
            ...conditionalStyleIfInvalid
        };
    }

    // For now offsets are hardcoded but the need to respect the split line in the middle for aesthetics
    const labelsDistanceFromTheEdge = -3;
    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={edgeStyle}/>

            {isTransferEdge &&
                <svg>
                    <text style={{fontSize: '12px', fill: transferLabelColor}}
                          dy={labelsDistanceFromTheEdge}
                    >
                        <textPath
                            href={`#${id}`} // Bind the text to the edge path
                            startOffset="25%"  // Position the text at the middle of the edge
                            textAnchor="middle" // Center the text on the path
                        >
                            TRANSFER
                        </textPath>
                    </text>
                    <text style={{fontSize: '12px', fill: transferLabelColor}}
                          dy={labelsDistanceFromTheEdge}
                    >
                        <textPath
                            href={`#${id}`} // Bind the text to the edge path
                            startOffset="75%"  // Position the text at the middle of the edge
                            textAnchor="middle" // Center the text on the path
                        >
                            TRANSFER
                        </textPath>
                    </text>
                    {/* Render the actual edge path here with the correct ID */}
                    <path id={id} d={edgePath} style={{fill: 'none', stroke: 'none'}}/>
                </svg>

            }
            {/*TODO @kbaran we need to introduce some abstraction to operate on those numbers*/}
            {passengerRatio != 100 &&
                <EdgeLabelRenderer>
                    <div style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        pointerEvents: 'all',
                        background: '#fff',
                        backgroundColor: labelColor,
                        color: '#fff',
                        border: `1px solid ${edgeLabelBorderColor}`,
                        padding: '2px 4px',
                        borderRadius: '3px',
                        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                         className="nodrag nopan"
                    >
                        <span>{passengerRatio}%</span>
                    </div>
                </EdgeLabelRenderer>}
        </>
    );
}