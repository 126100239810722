import {useDnD} from './DnDContext';
import FloatingComponent from "./FloatingComponent.tsx";

export interface FloatingToolboxProps {
    passengerFlow: string
}

const FloatingToolbox = ({passengerFlow} : FloatingToolboxProps) => {
    const { setPaxStageNode } = useDnD();

    const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string, processStage: string = 'test') => {

        // TODO @kbaran clean up
        if (nodeType === 'arrival') {
            setPaxStageNode({
                type: nodeType,
                stage: processStage,
                label: processStage
            })
        } else {
            setPaxStageNode({
                type: nodeType,
                stage: processStage,
                label: processStage
            })
        }

        event.dataTransfer.effectAllowed = 'move';
    };

    const arrival = (stage: string) => {
        return {
            "type": "arrival",
            "stage": stage
        }
    }

    const arrivals = [
        arrival('Gate'),
        arrival('Immigration'),
        arrival('Reclaim'),
        arrival('Customs'),
        arrival('Arrivals hall'),
    ]

    const departure = (stage: string) => {
        return {
            "type": "departure",
            "stage": stage
        }
    }

    const departures = [
        departure('Check-in'),
        departure('Security'),
        departure('Departure lounge'),
        departure('Emigration'),
        departure('Gate'),
    ]

    return (

        <>
            {passengerFlow === 'arrival' && (
                    <FloatingComponent position="left" topDistance={20} title="Arrivals">
                        {arrivals.map((arrival) => {
                            return (
                                <div className="dndnode generic-button"
                                     onDragStart={(event) => onDragStart(event, arrival.type, arrival.stage)} draggable>
                                    {arrival.stage}
                                </div>
                            )
                        })}

                    </FloatingComponent>)
            }
            {passengerFlow === 'departure' && (
                <FloatingComponent position="right" topDistance={20} title="Departure">
                    {departures.map((departure) => {
                        return (
                            <div className="dndnode generic-button"
                                 onDragStart={(event) => onDragStart(event, departure.type, departure.stage)} draggable>
                                {departure.stage}
                            </div>
                        )
                    })}

                </FloatingComponent>)
            }
        </>
    );
};

export default FloatingToolbox;