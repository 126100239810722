import React, {useEffect, useRef, useState} from "react";
import * as THREE from "three";
import loadGraphics from "../../functions/common.tsx";
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

//TODO TK  make it common for all zones
export interface PassportControlProps {
    makeFormVisible: () => void,
    setClickedComponentId: (name: string | null) => void,
    setPanelName: (name?: string) => void,
    hoveredComponents: string[] | null,
    setHoveredComponentId: (componentId: string | null) => void
}

function PassportControlZone(properties: PassportControlProps) {

    const zone = "passport_control"
    const setClickedComponentId = properties.setClickedComponentId
    const setPanelName = properties.setPanelName
    const hoveredComponents = properties.hoveredComponents
    const setHoveredComponentId = properties.setHoveredComponentId
    const manifestPath = 'passport_control/manifest.json';
    const groupRef = useRef<THREE.Group>(null);
    const gltfLoader = useRef(new GLTFLoader());
    const [models, setModels] = useState<React.ReactNode[]>([]);


    useEffect(() => {
        setPanelName('passport_control');
        console.log("Current zone set to passport_control")
        setClickedComponentId('');

        return () => {
            setPanelName(undefined);
            setClickedComponentId(null);
        };
    }, []);


    useEffect(() => {
        if (groupRef.current) {
            loadGraphics(
                gltfLoader.current,
                manifestPath,
                setClickedComponentId,
                setHoveredComponentId,
                zone
            ).then((elements) => {
                setModels(elements);
            });
        }
    }, []);

    useEffect(() => {
        if (groupRef.current) {
            groupRef.current.traverse((child) => {
                if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
                    // Check if hoveredComponents includes the current child componentId
                    if (hoveredComponents?.includes(child.userData.componentId)) {
                        child.material.emissive = new THREE.Color(0x0072c6);  // Set hover color
                        child.material.emissiveIntensity = 0.6;               // Set emissive intensity
                    } else {
                        child.material.emissive = child.userData.originalEmissive;  // Reset to original color
                    }
                }
            });
        }
    }, [hoveredComponents]);


    return (
        <group ref={groupRef}>
            {models.map((model) => model)}
        </group>
    );
}


export default PassportControlZone;