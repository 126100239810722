import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import loadGraphics from "../../functions/common.tsx";

type RightLaneProps = {
    setClickedComponentId: (name: string) => void,
    hoveredComponents: string[] | null,
    setHoveredComponentId: (componentId: string | null) => void,
    componentsForBottleneckSubprocess: string[]  // Precomputed bottleneck components
}

const RightLane: React.FC<RightLaneProps> = ({ setClickedComponentId, hoveredComponents, setHoveredComponentId, componentsForBottleneckSubprocess }) => {
    const manifestPath = '/security/right_lane/manifest.json';
    const groupRef = useRef<THREE.Group>(null);
    const gltfLoader = useRef(new GLTFLoader());
    const [models, setModels] = useState<React.ReactNode[]>([]);

    useEffect(() => {
        if (groupRef.current) {
            loadGraphics(
                gltfLoader.current,
                manifestPath,
                setClickedComponentId,
                setHoveredComponentId,
                "security"
            ).then((elements) => {
                setModels(elements);
            });
        }
    }, []);

    // Update the material properties for hovered and bottleneck components
    useEffect(() => {
        if (groupRef.current) {
            groupRef.current.traverse((child) => {
                if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
                    // Apply hover color if hovered, else apply bottleneck color if bottleneck
                    if (hoveredComponents?.includes(child.userData.componentId)) {
                        child.material.emissive = new THREE.Color(0x0072c6);  // Set hover color
                        child.material.emissiveIntensity = 0.6;               // Set emissive intensity
                    } else if (componentsForBottleneckSubprocess.includes(child.userData.componentId)) {
                        child.material.emissive = new THREE.Color(0x960000);  // Set bottleneck color
                        child.material.emissiveIntensity = 0.6;               // Set emissive intensity
                    } else {
                        child.material.emissive = child.userData.originalEmissive;  // Reset to original color
                    }
                }
            });
        }
    }, [hoveredComponents, componentsForBottleneckSubprocess]);  // Reapply logic when hoveredComponents or bottleneck components change

    return (
        <group castShadow ref={groupRef}>
            {models.map((model) => model)}
        </group>
    );
}

export default RightLane;
