import {NodeData} from "./DndComponent.tsx";
import {LABELS} from "./DndConstants.ts";
import {Edge} from "@xyflow/react";
import {ConfigurationPopupFormSpecification, ConfigurationPopupProperty, PropertyType} from "../common/popup/common.ts";
import {getRouteForProcess} from "../../processes.ts";

export interface DndComponentSpecification {
    type: 'edge' | 'node',
    componentSpecification: ConfigurationPopupFormSpecification
}

export function nodeToParameters(id: string, node: NodeData): DndComponentSpecification {

    const parameters = [
        {
            id: undefined, //TODO discuss with KB and either introduce this id here or revert
            displayName: LABELS.POPUPS.ALT_NAME,
            value: node.altName,
            type: 'text' as PropertyType
        },
        {
            id: undefined, //TODO discuss with KB and either introduce this id here or revert
            displayName: LABELS.POPUPS.COLOR,
            value: node.color? node.color : '#555555',
            type: 'colorPicker' as PropertyType
        },
    ]

    const link = getRouteForProcess(node.label.toLowerCase());

    const popupLink = {
        displayName: LABELS.POPUPS.DASHBOARD_LINK_LABEL,
        link: link
    }

    const componentSpecification: ConfigurationPopupFormSpecification = {
        componentId: id,
        displayName: node.label,
        parameters: parameters,
        link: link,
        popupLink: popupLink
    }

    return {
        type: 'node',
        componentSpecification
    }
}

export function componentSpecificationToNodeData(componentSpecification: ConfigurationPopupFormSpecification) {
    return {
        id: componentSpecification.componentId,
        nodeData: {
            altName: componentSpecification.parameters?.find((p: ConfigurationPopupProperty) => p.displayName === LABELS.POPUPS.ALT_NAME)?.value as string,
            color: componentSpecification.parameters?.find((p: ConfigurationPopupProperty) => p.displayName === LABELS.POPUPS.COLOR)?.value as string,
        }
    }
}

export function edgeToParameters(id: string, edge: Edge): DndComponentSpecification {
    const parameters = [
        {
            id: undefined, //TODO discuss with KB and either introduce this id here or revert
            displayName: LABELS.POPUPS.PASSENGER_RATIO,
            value: typeof edge.data?.passengerRatio === 'number' ? edge.data.passengerRatio : 100,
            type: 'percentage' as PropertyType
        },
        {
            id: undefined, //TODO discuss with KB and either introduce this id here or revert
            displayName: LABELS.POPUPS.WALKING_TIME,
            value: typeof edge.data?.walkingTime === 'string' ? edge.data.walkingTime : '00:00',
            type: 'duration' as PropertyType
        }
    ]

    const componentSpecification: ConfigurationPopupFormSpecification = {
        componentId: id,
        displayName: LABELS.POPUPS.EDGE_POPUP_LABEL,
        parameters: parameters
    }

    return {
        type: 'edge',
        componentSpecification
    }
}

export function parametersToEdge(componentSpecification: ConfigurationPopupFormSpecification) {
    const newRatioString = componentSpecification.parameters?.find(p => p.displayName === LABELS.POPUPS.PASSENGER_RATIO)?.value;

    return {
        id: componentSpecification.componentId,
        edgeData: {
            passengerRatio:  newRatioString ? Number(newRatioString) : undefined,
            walkingTime: componentSpecification.parameters?.find(p => p.displayName === LABELS.POPUPS.WALKING_TIME)?.value,
        }
    }
}