
export interface DndPaxBoundaryProps {
    position: number;
}

export function DndPaxBoundary({position}: DndPaxBoundaryProps) {
    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: `${position}px`,
                width: '5px',
                backgroundColor: 'white',
                zIndex: 1,
            }}
        />
    )
}