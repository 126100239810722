import React, {useEffect, useState} from 'react';
import './ConfigurationPopupForm.css';
import {ConfigurationPopupFormSpecification, ConfigurationPopupProperty} from "./common.ts";

type ConfigurationPopupFormProps = {
    initialData?: ConfigurationPopupFormSpecification;
    onSubmit: (cs: ConfigurationPopupFormSpecification) => void;
    onClose: () => void;
};

const ConfigurationPopupForm: React.FC<ConfigurationPopupFormProps> = ({ initialData, onSubmit, onClose }) => {
    if (!initialData) throw new Error("Form: initialData is required");

    const isStackedLayoutEnabled = true;
    const layout = isStackedLayoutEnabled ? 'labels-above' : 'labels-left';

    const [specification, setSpecification] = useState<ConfigurationPopupFormSpecification>(initialData);
    const [errors, setErrors] = useState<Record<string, string | null>>({});

    useEffect(() => {
        setSpecification(initialData);
    }, [initialData]);

    const validateField = (property: ConfigurationPopupProperty): string | null => {
        const value = property.value;
        switch (property.type) {
            case 'text':
                //We should add 'required' field to handle this case and not enforce all of the inputs to be required
                //return value === '' ? `${property.displayName} is required.` : null;
                return null
            case 'number'://for now this means positive number
            {
                const numValue = Number(value);

                if (isNaN(numValue)) {
                    return 'Value must be a number.';
                }

                return Number(value) < 0 ? 'Value must be a positive number.' : null
            }
            case 'duration':
                {
                    const durationPattern = /^([0-9]{1,2}):([0-5][0-9])$/;
                    return !durationPattern.test(String(value)) ? 'Please enter a valid duration in the [mm:ss] format.' : null;
                }
            case 'percentage':
                {
                    const numValue = Number(value);

                    // Check if the value is a number
                    if (isNaN(numValue)) {
                        return 'Value must be a number.';
                    }
                    return numValue < 0 || numValue > 100 ? 'Percentage must be between 0 and 100.' : null;
                }
            default:
                return null;
        }
    };

    const handlePropertyChange = (index: number, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const updatedParameters = [...(specification.parameters || [])];
        const updatedProperty = { ...updatedParameters[index], value: e.target.value };
        updatedParameters[index] = updatedProperty;
        const updatedData = { ...specification, parameters: updatedParameters };
        setSpecification(updatedData);
        const error = validateField(updatedProperty);
        setErrors((prev) => ({ ...prev, [updatedProperty.id ?? index]: error }));
    };

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Validate all fields before submitting
        const newErrors: Record<string, string | null> = {};
        specification.parameters.forEach((property, index) => {
            newErrors[property.id ?? index] = validateField(property);
        });

        setErrors(newErrors);

        // Only submit if there are no errors
        const hasErrors = Object.values(newErrors).some((error) => error !== null);
        if (!hasErrors) {
            onSubmit(specification);
        }
    };

    return (
        <div className={`configuration-popup-container ${layout}`}>
            {/* title */}
            <div className="configuration-popup-title">
                <h3>{specification.displayName} properties </h3>
                {
                    specification.link && (
                        <a href={specification.link} className='generic-button configuration-popup-link'> Dashboard </a>
                    )
                }

            </div>

            {/* form */}
            <form id="componentSpecForm" className="componentSpecConfig" onSubmit={handleFormSubmit}>
                <div className="configuration-popup-configuration-content">
                    {specification.parameters?.map((property, index) => (
                        <div key={index} className="configuration-popup-configuration-form-input-row">
                            <label className="configuration-popup-input-label" htmlFor={property.displayName}>
                                {property.displayName}
                            </label>
                            {
                                property.type === 'colorPicker' && (
                                    <input
                                        type="color"
                                        value={property.value as string}
                                        onChange={(e) => handlePropertyChange(index, e)}
                                        className={errors[property.id ?? index] ? 'error' : ''}
                                    />
                                )
                            }
                            {
                                property.type !== 'colorPicker' && (
                                    <>
                                        <input
                                            // type={property.type === 'number' ? 'number' : 'text'} to be decided if we want to use real type here or just rely on text always
                                            type='text'
                                            value={property.value}
                                            onChange={(e) => handlePropertyChange(index, e)}
                                            className={errors[property.id ?? index] ? 'error' : ''}
                                        />
                                        {errors[property.id ?? index] && (
                                            <span className="error-message">{errors[property.id ?? index]}</span>
                                        )}
                                    </>
                                )}
                        </div>
                    ))}
                </div>
            </form>

            {/* buttons */}
            <div className="configuration-popup-submit-close-buttons-container">
                <button
                    type="button"
                    className="configuration-popup-submit-close-button configuration-popup-button-close"
                    onClick={onClose}
                >
                    Close
                </button>
                <button
                    type="submit"
                    className="configuration-popup-submit-close-button configuration-popup-button-submit"
                    form="componentSpecForm"
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default ConfigurationPopupForm;
