import {ConfigurationPopupFormSpecification} from "../components/common/popup/common.ts";

export const recalculateBeltAdjustedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>
): number => {
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const beltSpec = components.find(component => component.componentId === "belt");
        const traySpec = components.find(component => component.componentId === "tray");

        if (beltSpec && traySpec) {
            const beltSpeed = parseFloat(
                beltSpec.parameters.find(param => param.id === "speed")?.value.toString().replace(',', '.') || '0'
            );
            const trayLength = parseFloat(
                traySpec.parameters.find(param => param.id === "length")?.value.toString().replace(',', '.') || '0'
            );
            const trayGap = parseFloat(
                traySpec.parameters.find(param => param.id === "gap")?.value.toString().replace(',', '.') || '0'
            );

            const uncappedBeltThroughput = Math.floor(3600 * beltSpeed / (trayLength + trayGap));

            console.log(`Uncapped Belt Throughput: ${uncappedBeltThroughput}`);

            return uncappedBeltThroughput;
        }
    }

    return 0;
};

export const recalculateBeltUncappedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>
): number => {
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const traySpec = components.find(component => component.componentId === "tray");

        if (traySpec) {
            const adjustedBeltThroughput = recalculateBeltAdjustedThroughputForZone(currentZone, terminalZonesComponentsSpecification);

            const traysPerPassenger = parseFloat(
                traySpec.parameters.find(param => param.id === "trays_per_passenger")?.value.toString().replace(',', '.') || '1'
            );

            const uncappedBeltThroughput = adjustedBeltThroughput / traysPerPassenger;

            console.log(`Uncapped Belt Throughput: ${uncappedBeltThroughput}`);
            return uncappedBeltThroughput;
        }
    }

    return 0;
};

const recalculateXRayAdjustedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>
): number => {
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const xRaySpec = components.find(component => component.componentId === "x_ray");
        const traySpec = components.find(component => component.componentId === "tray");

        if (xRaySpec && traySpec) {
            const xRayScreeningTime = parseFloat(
                xRaySpec.parameters.find(param => param.id === "screening_time")?.value.toString().replace(',', '.') || '0'
            );
            const xRayBeltLength = parseFloat(
                xRaySpec.parameters.find(param => param.id === "belt_length")?.value.toString().replace(',', '.') || '0'
            );
            const trayLength = parseFloat(
                traySpec.parameters.find(param => param.id === "length")?.value.toString().replace(',', '.') || '0'
            );
            const trayGap = parseFloat(
                traySpec.parameters.find(param => param.id === "gap")?.value.toString().replace(',', '.') || '0'
            );

            const uncappedXRayThroughput = Math.floor(3600 / xRayScreeningTime * xRayBeltLength / (trayLength + trayGap));

            console.log(`Uncapped X-ray Throughput: ${uncappedXRayThroughput}`);

            return uncappedXRayThroughput;
        }
    }

    return 0;
};

export const recalculateXRayUncappedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>
): number => {
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const traySpec = components.find(component => component.componentId === "tray");

        if (traySpec) {
            const adjustedXRayThroughput = recalculateXRayAdjustedThroughputForZone(currentZone, terminalZonesComponentsSpecification);

            const traysPerPassenger = parseFloat(
                traySpec.parameters.find(param => param.id === "trays_per_passenger")?.value.toString().replace(',', '.') || '1'
            );

            const uncappedXRayThroughput = adjustedXRayThroughput / traysPerPassenger;

            console.log(`Uncapped X-ray Throughput: ${uncappedXRayThroughput}`);
            return uncappedXRayThroughput;
        }
    }

    return 0;
};


const recalculateRecirculatedTraysAdjustedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>,
): number => {
    const components = terminalZonesComponentsSpecification[currentZone];
    console.log("recalculateRecirculatedTraysUncappedThroughputForZone")
    if (components) {
        const xRaySpec = components.find(component => component.componentId === "x_ray");
        const rejectLaneSpec = components.find(component => component.componentId === "reject");

        if (xRaySpec && rejectLaneSpec) {
            const uncappedXRayThroughput = recalculateXRayAdjustedThroughputForZone(
                currentZone,
                terminalZonesComponentsSpecification
            );

            const trayRejectRate = parseFloat(
                rejectLaneSpec.parameters.find(param => param.id === "tray_reject_rate")?.value.toString().replace(',', '.') || '0'
            ) / 100;

            //TODO TK ask Tomek where we should place - re_screened_rejected_trays
            const reScreenedRejectedTrays = parseFloat(
                rejectLaneSpec.parameters.find(param => param.id === "re_screened_rejected_trays")?.value.toString().replace(',', '.') || '0'
            ) / 100;


            console.log(trayRejectRate, + "  " + reScreenedRejectedTrays + "  " + uncappedXRayThroughput)

            const uncappedRecirculatedTraysThroughput = Math.ceil(
                uncappedXRayThroughput / (1 + trayRejectRate * reScreenedRejectedTrays)
            );

            console.log(`Uncapped Recirculated Trays Throughput: ${uncappedRecirculatedTraysThroughput}`);

            return uncappedRecirculatedTraysThroughput;
        }
    }

    return 0;  // Default value if components are not found
};

export const recalculateRecirculatedTraysUncappedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>
): number => {
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const traySpec = components.find(component => component.componentId === "tray");

        if (traySpec) {
            const adjustedRecirculatedTraysThroughput = recalculateRecirculatedTraysAdjustedThroughputForZone(currentZone, terminalZonesComponentsSpecification);

            const traysPerPassenger = parseFloat(
                traySpec.parameters.find(param => param.id === "trays_per_passenger")?.value.toString().replace(',', '.') || '1'
            );

            const uncappedRecirculatedTraysThroughput = adjustedRecirculatedTraysThroughput / traysPerPassenger;

            console.log(`Uncapped Recirculated Trays Throughput: ${uncappedRecirculatedTraysThroughput}`);
            return uncappedRecirculatedTraysThroughput;
        }
    }

    return 0;
};

// const recalculateDivestmentAdjustedThroughputForZone = (
//     currentZone: string,
//     terminalZonesComponentsSpecification: Record<string, ComponentSpecification[]>,
// ): number => {
//     // Get components for the current zone
//     const components = terminalZonesComponentsSpecification[currentZone];
//     if (components) {
//         const divestmentSpec = components.find(component => component.componentId === "divestment_stations");
//         const recirculatedTraysSpec = components.find(component => component.componentId === "tray");
//
//         if (divestmentSpec && recirculatedTraysSpec) {
//             // Calculate uncapped throughput for recirculated trays
//             const uncappedRecirculatedTraysThroughput = recalculateRecirculatedTraysAdjustedThroughputForZone(
//                 currentZone,
//                 terminalZonesComponentsSpecification,
//             );
//
//             // Extract parameters using parameter IDs
//             const divestmentStations = parseFloat(
//                 divestmentSpec.parameters.find(param => param.id === "divestment_positions")?.value.toString().replace(',', '.') || '0'
//             );
//             const averageServiceTimePerTray = parseFloat(
//                 divestmentSpec.parameters.find(param => param.id === "service_time_per_tray")?.value.toString().replace(',', '.') || '0'
//             );
//
//             // Calculate the uncapped throughput for divestment stations
//             const uncappedDivestmentThroughput = Math.min(
//                 divestmentStations * 3600 / averageServiceTimePerTray,
//                 uncappedRecirculatedTraysThroughput
//             );
//
//             console.log(`Uncapped Divestment Throughput: ${uncappedDivestmentThroughput}`);
//
//             return uncappedDivestmentThroughput;  // Return the uncapped throughput
//         }
//     }
//
//     return 0;  // Default value if components are not found
// };

export const recalculateDivestmentUncappedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>
): number => {
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const divestmentSpec = components.find(component => component.componentId === "divestment_stations");
        const traySpec = components.find(component => component.componentId === "tray");

        const divestmentStations = divestmentSpec?.parameters.find(param => param.id === "divestment_positions");
        const averageServiceTimePerTray = divestmentSpec?.parameters.find(param => param.id === "service_time_per_tray");
        const traysPerPassenger = traySpec?.parameters.find(param => param.id === "trays_per_passenger");


        if (divestmentStations && averageServiceTimePerTray && traysPerPassenger) {
            const numDivestmentStations = parseFloat(divestmentStations.value.toString().replace(',', '.') || '0');
            const numAverageServiceTimePerTray = parseFloat(averageServiceTimePerTray.value.toString().replace(',', '.') || '0');
            const numTraysPerPassenger = parseFloat(traysPerPassenger.value.toString().replace(',', '.') || '1');

            const uncappedDivestmentThroughput = numDivestmentStations * 3600 / numAverageServiceTimePerTray / numTraysPerPassenger;

            console.log(`Uncapped Divestment Throughput: ${uncappedDivestmentThroughput}`);

            return uncappedDivestmentThroughput;  // Return the uncapped throughput
        }
    }

    return 0;
};


export const recalculateBodyScannerAdjustedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>,
    lanes: number  // The number of lanes
): number => {
    // Get components for the current zone
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const bodyScannerSpec = components.find(component => component.componentId === "body_scanner");
        const divestmentSpec = components.find(component => component.componentId === "divestment_stations");

        if (bodyScannerSpec && divestmentSpec) {
            // Calculate uncapped throughput for divestment stations
            const uncappedDivestmentThroughput = recalculateDivestmentUncappedThroughputForZone(
                currentZone,
                terminalZonesComponentsSpecification
            );

            // Extract parameters using parameter IDs
            const numberOfBodyScanners = parseFloat(
                bodyScannerSpec.parameters.find(param => param.id === "number_of_scanners")?.value.toString().replace(',', '.') || '0'
            );
            const bodyScannerServiceTime = parseFloat(
                bodyScannerSpec.parameters.find(param => param.id === "scanning_service_time")?.value.toString().replace(',', '.') || '0'
            );
            const bodyScannerRate = parseFloat(
                bodyScannerSpec.parameters.find(param => param.id === "scanning_rate")?.value.toString().replace(',', '.') || '0'
            ) / 100;  // Convert percent to decimal

            // Calculate the body scanner throughput
            const bodyScannerThroughput = Math.min(
                uncappedDivestmentThroughput * lanes,  // E18
                Math.floor(numberOfBodyScanners * 3600 / bodyScannerServiceTime / bodyScannerRate)  // E27 * 3600 / E22 / (E21 / 100)
            );

            console.log(`uncappedDivestmentThroughput: ${uncappedDivestmentThroughput}  Body Scanner Throughput: ${bodyScannerThroughput}`);

            return bodyScannerThroughput;  // Return the adjusted throughput
        }
    }

    return 0;  // Default value if components are not found
};


export const recalculateBodyScannerUncappedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>
): number => {
    // Get components for the current zone
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const bodyScannerSpec = components.find(component => component.componentId === "body_scanner");

        if (bodyScannerSpec) {
            // Extract parameters using parameter IDs
            const numberOfBodyScanners = parseFloat(
                bodyScannerSpec.parameters.find(param => param.id === "number_of_scanners")?.value.toString().replace(',', '.') || '0'
            );
            const bodyScannerServiceTime = parseFloat(
                bodyScannerSpec.parameters.find(param => param.id === "scanning_service_time")?.value.toString().replace(',', '.') || '0'
            );
            const bodyScannerRate = parseFloat(
                bodyScannerSpec.parameters.find(param => param.id === "scanning_rate")?.value.toString().replace(',', '.') || '0'
            ) / 100;  // Convert percent to decimal

            // Calculate the uncapped body scanner throughput
            const uncappedBodyScannerThroughput = Math.floor(numberOfBodyScanners * 3600 / bodyScannerServiceTime / bodyScannerRate) / 2;

            console.log(`Uncapped Body Scanner Throughput: ${uncappedBodyScannerThroughput}`);

            return uncappedBodyScannerThroughput;  // Return the uncapped throughput
        }
    }

    return 0;  // Default value if components are not found
};

export const recalculateBodySearchAdjustedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>,
): number => {
    // Get components for the current zone
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const bodySearchSpec = components.find(component => component.componentId === "body_search");
        const bodyScannerSpec = components.find(component => component.componentId === "body_scanner");

        if (bodySearchSpec && bodyScannerSpec) {
            // Calculate adjusted throughput for body scanner
            const adjustedBodyScannerThroughput = recalculateBodyScannerAdjustedThroughputForZone(
                currentZone,
                terminalZonesComponentsSpecification,
                2  // TODO ask Tomek where we should display this property
            );

            // Extract parameters using parameter IDs
            const femaleSearchAgents = parseFloat(
                bodySearchSpec.parameters.find(param => param.id === "female_agents")?.value.toString().replace(',', '.') || '0'
            );
            const maleSearchAgents = parseFloat(
                bodySearchSpec.parameters.find(param => param.id === "male_agents")?.value.toString().replace(',', '.') || '0'
            );
            const bodySearchTime = parseFloat(
                bodySearchSpec.parameters.find(param => param.id === "service_time")?.value.toString().replace(',', '.') || '0'
            );
            const bodyScannerAlarmRate = parseFloat(
                bodySearchSpec.parameters.find(param => param.id === "alarm_rate")?.value.toString().replace(',', '.') || '0'
            ) / 100;  // Convert percent to decimal
            const bodyScannerRate = parseFloat(
                bodyScannerSpec.parameters.find(param => param.id === "scanning_rate")?.value.toString().replace(',', '.') || '0'
            ) / 100;  // Convert percent to decimal

            // Calculate the adjusted throughput for body search
            const bodySearchThroughput = Math.min(
                adjustedBodyScannerThroughput,
                Math.floor(femaleSearchAgents * 3600 / bodySearchTime / bodyScannerAlarmRate / bodyScannerRate) +
                Math.floor(maleSearchAgents * 3600 / bodySearchTime / bodyScannerAlarmRate / bodyScannerRate)
            );

            console.log(`Body Search Adjusted Throughput: ${bodySearchThroughput}`);

            return bodySearchThroughput;  // Return the adjusted throughput
        }
    }

    return 0;  // Default value if components are not found
};

export const recalculateFemaleBodySearchUncappedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>
): number => {
    // Get components for the current zone
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const bodySearchSpec = components.find(component => component.componentId === "body_search");
        const bodyScannerSpec = components.find(component => component.componentId === "body_scanner");

        if (bodySearchSpec && bodyScannerSpec) {
            // Extract parameters using parameter IDs
            const femaleSearchAgents = parseFloat(
                bodySearchSpec.parameters.find(param => param.id === "female_agents")?.value.toString().replace(',', '.') || '0'
            );
            const bodySearchTime = parseFloat(
                bodySearchSpec.parameters.find(param => param.id === "service_time")?.value.toString().replace(',', '.') || '0'
            );
            const bodyScannerAlarmRate = parseFloat(
                bodySearchSpec.parameters.find(param => param.id === "alarm_rate")?.value.toString().replace(',', '.') || '0'
            ) / 100;  // Convert percent to decimal
            const bodyScannerRate = parseFloat(
                bodyScannerSpec.parameters.find(param => param.id === "scanning_rate")?.value.toString().replace(',', '.') || '0'
            ) / 100;  // Convert percent to decimal
            const maleProportion = parseFloat(
                bodySearchSpec.parameters.find(param => param.id === "male_proportion")?.value.toString().replace(',', '.') || '0'
            ) / 100;  // Convert percent to decimal

            // Calculate the uncapped female body search throughput
            const femaleBodySearchThroughput = Math.floor(femaleSearchAgents * 3600 / bodySearchTime / bodyScannerAlarmRate / bodyScannerRate) / (1 - maleProportion) / 2;

            console.log(`Uncapped Female Body Search Throughput: ${femaleBodySearchThroughput}`);

            return femaleBodySearchThroughput;  // Return the uncapped throughput
        }
    }

    return 0;  // Default value if components are not found
};

export const recalculateMaleBodySearchUncappedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>
): number => {
    // Get components for the current zone
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const bodySearchSpec = components.find(component => component.componentId === "body_search");
        const bodyScannerSpec = components.find(component => component.componentId === "body_scanner");

        if (bodySearchSpec && bodyScannerSpec) {
            // Extract parameters using parameter IDs
            const maleSearchAgents = parseFloat(
                bodySearchSpec.parameters.find(param => param.id === "male_agents")?.value.toString().replace(',', '.') || '0'
            );
            const bodySearchTime = parseFloat(
                bodySearchSpec.parameters.find(param => param.id === "service_time")?.value.toString().replace(',', '.') || '0'
            );
            const bodyScannerAlarmRate = parseFloat(
                bodySearchSpec.parameters.find(param => param.id === "alarm_rate")?.value.toString().replace(',', '.') || '0'
            ) / 100;  // Convert percent to decimal
            const bodyScannerRate = parseFloat(
                bodyScannerSpec.parameters.find(param => param.id === "scanning_rate")?.value.toString().replace(',', '.') || '0'
            ) / 100;  // Convert percent to decimal
            const maleProportion = parseFloat(
                bodySearchSpec.parameters.find(param => param.id === "male_proportion")?.value.toString().replace(',', '.') || '0'
            ) / 100;  // Convert percent to decimal

            // Calculate the uncapped male body search throughput
            const maleBodySearchThroughput = Math.floor(maleSearchAgents * 3600 / bodySearchTime / bodyScannerAlarmRate / bodyScannerRate) / maleProportion / 2;

            console.log(`Uncapped Male Body Search Throughput: ${maleBodySearchThroughput}`);

            return maleBodySearchThroughput;  // Return the uncapped throughput
        }
    }

    return 0;  // Default value if components are not found
};

export const recalculateRejectStationsUncappedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>
): number => {
    // Get components for the current zone
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const rejectSpec = components.find(component => component.componentId === "reject");
        const traySpec = components.find(component => component.componentId === "tray");

        if (rejectSpec && traySpec) {
            // Extract parameters using parameter IDs
            const rejectStationNumber = parseFloat(
                rejectSpec.parameters.find(param => param.id === "reject_stations")?.value.toString().replace(',', '.') || '0'
            );
            const traySearchTime = parseFloat(
                rejectSpec.parameters.find(param => param.id === "tray_search_time")?.value.toString().replace(',', '.') || '0'
            );
            const trayRejectRate = parseFloat(
                rejectSpec.parameters.find(param => param.id === "tray_reject_rate")?.value.toString().replace(',', '.') || '0'
            ) / 100;  // Convert percent to decimal
            const traysPerPassenger = parseFloat(
                traySpec.parameters.find(param => param.id === "trays_per_passenger")?.value.toString().replace(',', '.') || '1'
            );

            // Calculate the uncapped throughput for reject stations
            const uncappedRejectStationsThroughput = Math.floor(rejectStationNumber * 3600 / traySearchTime / trayRejectRate) / traysPerPassenger;

            console.log(`Uncapped Reject Stations Throughput: ${uncappedRejectStationsThroughput}`);

            return uncappedRejectStationsThroughput;  // Return the uncapped throughput
        }
    }

    return 0;  // Default value if components are not found
};

export const recalculateRejectLaneUncappedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>
): number => {
    // Get components for the current zone
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const rejectSpec = components.find(component => component.componentId === "reject");
        const traySpec = components.find(component => component.componentId === "tray");

        if (rejectSpec && traySpec) {

            const rejectStationNumber = parseFloat(
                rejectSpec.parameters.find(param => param.id === "reject_stations")?.value.toString().replace(',', '.') || '0'
            );
            const traySearchTime = parseFloat(
                rejectSpec.parameters.find(param => param.id === "tray_search_time")?.value.toString().replace(',', '.') || '0'
            );
            const trayRejectRate = parseFloat(
                rejectSpec.parameters.find(param => param.id === "tray_reject_rate")?.value.toString().replace(',', '.') || '0'
            ) / 100;  // Convert percent to decimal
            const maxPassengerWaitingTime = parseFloat(
                rejectSpec.parameters.find(param => param.id === "max_passenger_waiting_time")?.value.toString().replace(',', '.') || '0'
            )
            const rejectLaneCapacity = parseFloat(
                rejectSpec.parameters.find(param => param.id === "reject_lane_capacity")?.value.toString().replace(',', '.') || '0'
            );
            const traysPerPassenger = parseFloat(
                traySpec.parameters.find(param => param.id === "trays_per_passenger")?.value.toString().replace(',', '.') || '1'
            );

            const rejectLaneCapacityTarget = Math.ceil(rejectStationNumber * maxPassengerWaitingTime / (traySearchTime / 60));

            const uncappedRejectLaneThroughput = Math.floor(rejectStationNumber * (3600 - (rejectLaneCapacityTarget - rejectLaneCapacity) * traySearchTime) / traySearchTime / trayRejectRate) / traysPerPassenger;

            console.log(`Uncapped Reject Lane Throughput: ${uncappedRejectLaneThroughput}`);

            return uncappedRejectLaneThroughput;  // Return the uncapped throughput
        }
    }

    return 0;  // Default value if components are not found
};

export const recalculateRejectAdjustedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>,
    lanes: number  // Number of lanes
): number => {
    // Get components for the current zone
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const rejectSpec = components.find(component => component.componentId === "reject");
        const divestmentSpec = components.find(component => component.componentId === "divestment_stations");

        if (rejectSpec && divestmentSpec) {
            // Calculate adjusted throughput for body search
            const adjustedBodySearchThroughput = recalculateBodySearchAdjustedThroughputForZone(
                currentZone,
                terminalZonesComponentsSpecification,
            );

            // Calculate K29: Adjusted throughput for body search divided by lanes
            const adjustedBodySearchThroughputPerLane = adjustedBodySearchThroughput / lanes;

            // Extract parameters using parameter IDs
            const rejectStationNumber = parseFloat(
                rejectSpec.parameters.find(param => param.id === "reject_stations")?.value.toString().replace(',', '.') || '0'
            );
            const traySearchTime = parseFloat(
                rejectSpec.parameters.find(param => param.id === "tray_search_time")?.value.toString().replace(',', '.') || '0'
            );
            const trayRejectRate = parseFloat(
                rejectSpec.parameters.find(param => param.id === "tray_reject_rate")?.value.toString().replace(',', '.') || '0'
            ) / 100;  // Convert percent to decimal
            const traysPerPassenger = parseFloat(
                divestmentSpec.parameters.find(param => param.id === "trays_per_passenger")?.value.toString().replace(',', '.') || '1'
            );

            // Calculate the adjusted throughput for reject
            const rejectThroughput = Math.min(
                Math.floor(rejectStationNumber * 3600 / traySearchTime / trayRejectRate),
                adjustedBodySearchThroughputPerLane * traysPerPassenger
            );

            console.log(`Reject Adjusted Throughput: ${rejectThroughput}`);

            return rejectThroughput;  // Return the adjusted throughput
        }
    }

    return 0;  // Default value if components are not found
};

export const recalculateClearLaneUncappedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>
): number => {
    // Get components for the current zone
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const clearLaneSpec = components.find(component => component.componentId === "clear_lane");
        const traySpec = components.find(component => component.componentId === "tray");

        if (clearLaneSpec && traySpec) {
            // Extract parameters using parameter IDs
            const clearLaneLength = parseFloat(
                clearLaneSpec.parameters.find(param => param.id === "clear_lane_capacity")?.value.toString().replace(',', '.') || '0'
            );
            const onLaneRepackPercent = parseFloat(
                clearLaneSpec.parameters.find(param => param.id === "on_lane_repack_percent")?.value.toString().replace(',', '.') || '0'
            ) / 100;
            const onLaneRepackServiceTime = parseFloat(
                clearLaneSpec.parameters.find(param => param.id === "on_lane_repack_time")?.value.toString().replace(',', '.') || '0'
            );
            const offLaneRepackServiceTime = parseFloat(
                clearLaneSpec.parameters.find(param => param.id === "pickup_to_off_lane_repack_time")?.value.toString().replace(',', '.') || '0'
            );
            const traysPerPassenger = parseFloat(
                traySpec.parameters.find(param => param.id === "trays_per_passenger")?.value.toString().replace(',', '.') || '1'
            );

            // Calculate the uncapped throughput for clear lane
            const uncappedClearLaneThroughput = Math.floor(
                clearLaneLength * 3600 / (onLaneRepackPercent * onLaneRepackServiceTime + (1 - onLaneRepackPercent) * offLaneRepackServiceTime)
            ) / traysPerPassenger;

            console.log(`Uncapped Clear Lane Throughput: ${uncappedClearLaneThroughput}`);

            return uncappedClearLaneThroughput;  // Return the uncapped throughput
        }
    }

    return 0;  // Default value if components are not found
};




export const recalculateRepackUncappedThroughputForZone = (
    currentZone: string,
    terminalZonesComponentsSpecification: Record<string, ConfigurationPopupFormSpecification[]>
): number => {
    // Get components for the current zone
    const components = terminalZonesComponentsSpecification[currentZone];

    if (components) {
        const rePackSpec = components.find(component => component.componentId === "re_pack");
        const clearLaneSpec = components.find(component => component.componentId === "clear_lane");
        const traySpec = components.find(component => component.componentId === "tray");

        if (rePackSpec && traySpec && clearLaneSpec) {
            // Extract parameters using parameter IDs
            const offLaneRepackStationsNumber = parseFloat(
                rePackSpec.parameters.find(param => param.id === "off_lane_repack_stations")?.value.toString().replace(',', '.') || '0'
            );
            const offLaneRepackPercent =  (100 - parseFloat(
                clearLaneSpec.parameters.find(param => param.id === "on_lane_repack_percent")?.value.toString().replace(',', '.') || '0'
            )) /100
            const offLaneRepackServiceTime = parseFloat(
                rePackSpec.parameters.find(param => param.id === "off_lane_repack_time")?.value.toString().replace(',', '.') || '0'
            );
            const traysPerPassenger = parseFloat(
                traySpec.parameters.find(param => param.id === "trays_per_passenger")?.value.toString().replace(',', '.') || '1'
            );

            //TODO TK it implement adjustedThroughput and reuse here
            const uncappedRepackThroughput = (Math.floor(
                offLaneRepackStationsNumber * 3600 / (offLaneRepackPercent * offLaneRepackServiceTime)
            ) / traysPerPassenger / 2) / traysPerPassenger;

            console.log(`Uncapped Re-pack Throughput: ${uncappedRepackThroughput}`);

            return uncappedRepackThroughput;  // Return the uncapped throughput
        }
    }

    return 0;  // Default value if components are not found
};
