import React from 'react';
import './DndNode.css';
import { Handle, NodeProps, Position} from '@xyflow/react';


export interface CustomNodeProps extends NodeProps {
    data: {
        label: string;
        description?: string;
        altName?: string;
        color?: string;
    };

}

const DndNode: React.FC<CustomNodeProps> = ({
                                                data
                                            }: CustomNodeProps) => {

    const getColor = (color: string | undefined) => {
        if (color) {
            return color
        } else {
            return '#555555'
        }
    }

    const color = getColor(data.color);

    return (
        <div className="custom-node" style={{'borderColor': color}}>
            <div className="custom-node-header">
                <span>{data.label}</span>
            </div>
            <div className={'custom-node-body'}>
                {
                    data.altName &&
                    <div className="custom-node-name">
                        <span> {data.altName} </span>
                    </div>

                }
            </div>

            <Handle
                type="source"
                position={Position.Bottom}
                id="source-handle"
                style={{background: '#555'}}
            />
            <Handle
                type="target"
                position={Position.Top}
                id="target-handle"
                style={{background: '#555'}}
            />
        </div>
    );
};

export default DndNode;