import {useNavigate} from "react-router-dom";
import routes from "../../routes.ts";

export default function Logo() {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(routes.home);
    };


    return (
        <div className={'logo-container'} onClick={handleClick} style={{ cursor: 'pointer' }} >

            <img src="/common/combined_logos.png" alt="Combined Logos"/>
        </div>
    );
}