import {ConfigurationPopupFormSpecification} from "./popup/common.ts";
import PeakHourPassengerDemand from "../security/PeakHourPassengerDemand.tsx";

interface InputsProps {
    inputSpecifications: ConfigurationPopupFormSpecification[];  // Array of input specifications
    setClickedInputId: (inputId: string) => void,
    hoveredInputId: string | null,
    setHoveredInputId: (inputId: string | null) => void,
    inputsForBottleneckProcess: string[]
    makeIsDemandClicked: () => void,
}

const Inputs: React.FC<InputsProps> = ({
                                           inputSpecifications,
                                           setClickedInputId,
                                           hoveredInputId,
                                           setHoveredInputId,
                                           inputsForBottleneckProcess,
                                           makeIsDemandClicked
                                       }) => {
    return (
        <div className={'widget inputs-container'}>
            <h1>Inputs</h1>
            <PeakHourPassengerDemand makeIsDemandClicked={makeIsDemandClicked}/>
            {inputSpecifications.map((inputSpec) => (
                <button
                    className={`generic-button ${hoveredInputId === inputSpec.componentId ? 'hover' : ''} 
                     ${inputsForBottleneckProcess.includes(inputSpec.componentId) ? 'bottleneck-button' : ''}`
                    }
                    key={inputSpec.componentId}
                    onPointerOver={() => {
                        setHoveredInputId(inputSpec.componentId);
                    }}
                    onPointerOut={() => {
                        setHoveredInputId(null);  // Reset the hovered input
                    }}
                    onClick={() => {
                        setClickedInputId(inputSpec.componentId);  // Set clicked input ID
                    }}
                >
                    {inputSpec.displayName} {/* Use displayName for the button content */}
                </button>
            ))}
        </div>
    );
};

export default Inputs;
