import { useEffect } from "react";
import RightLane from "./RightLane.tsx";
import BetweenLanes from "./BetweenLanes.tsx";
import LeftLane from "./LeftLane.tsx";

export interface SecurityProps {
    makeFormVisible: () => void,
    setClickedComponentId: (name: string | null) => void,
    setPanelName: (name?: string) => void,
    hoveredComponents: string[] | null,
    setHoveredComponentId: (componentId: string | null) => void,
    componentsForBottleneckSubprocess: string[]  // Bottleneck components passed from parent
}

function Security(properties: SecurityProps) {
    const setClickedComponentId = properties.setClickedComponentId;
    const setPanelName = properties.setPanelName;
    const hoveredComponents = properties.hoveredComponents;
    const setHoveredComponentId = properties.setHoveredComponentId;
    const componentsForBottleneckSubprocess = properties.componentsForBottleneckSubprocess;  // Destructure bottleneckComponents

    useEffect(() => {
        setPanelName('security');
        setClickedComponentId('');

        return () => {
            setPanelName(undefined);
            setClickedComponentId(null);
        };
    }, []);

    return (
        <>
            <RightLane
                setClickedComponentId={setClickedComponentId}
                hoveredComponents={hoveredComponents}
                setHoveredComponentId={setHoveredComponentId}
                componentsForBottleneckSubprocess={componentsForBottleneckSubprocess}
            />
            <BetweenLanes
                setClickedComponentId={setClickedComponentId}
                hoveredComponents={hoveredComponents}
                setHoveredComponentId={setHoveredComponentId}
                componentsForBottleneckSubprocess={componentsForBottleneckSubprocess}
            />
            <LeftLane
                setClickedComponentId={setClickedComponentId}
                hoveredComponents={hoveredComponents}
                setHoveredComponentId={setHoveredComponentId}
                componentsForBottleneckSubprocess={componentsForBottleneckSubprocess}
            />
        </>
    );
}

export default Security;
