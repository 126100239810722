import React from "react";
import {CanvasTexture, Texture, Vector3} from "three";

/**
 * TODO @kbaran
 * Clean up the code here. This is a result of several trials and errors of implementing proper labels.
 * Requirements were this label must be part of the scene to be able to be hovered over smoothly and to be clickable
 * I started with <Html> element but it was loosing context and it was impossible to integrate the div inside into a scene
 * Second trial was Text which was hard to work with when it comes to camera orientation. I ended up setting it up to look at the camera
 * but it was misbehaving when camera was too close or too far away and would require additional code to manage perspective
 * Then I tried Sprite which was the best solution so far. It is part of the scene and it is always facing the camera in proper way
 */

const createLabelTexture = (text: string, bgColor: string , textColor: string = 'white'): Texture => {

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
        throw new Error('Unable to get canvas context');
    }

    const fontSize = 64; // Increase font size for higher resolution
    context.font = `${fontSize}px Arial`;

    // Set canvas dimensions based on the text with a higher resolution
    const padding = 20; // Increase padding for better appearance
    const textWidth = context.measureText(text).width;
    canvas.width = textWidth + padding * 2;
    canvas.height = fontSize * 1.4 + padding * 2;

    // Draw the background
    context.fillStyle = bgColor;
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw the text
    context.font = `${fontSize}px Arial`;
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    context.fillStyle = textColor; // Text color
    context.fillText(text, canvas.width / 2, canvas.height / 2);

    return new CanvasTexture(canvas);
};

export interface SimpleTooltipSpriteProps {
    tooltipText: string,
    isThisTooltipHovered: boolean,
    position: [number, number, number]
}

export default function SimpleTooltipSprite({
                                          tooltipText,
                                          isThisTooltipHovered,
                                          position,
                                      }: SimpleTooltipSpriteProps) {

    const text = tooltipText;

    const color = isThisTooltipHovered ? 'rgba(255, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.7)';

    const texture = React.useMemo(() => createLabelTexture(text, color), [text, color,]);


    const aspect = texture.image.width / texture.image.height;
    const baseScale = 5
    const scale = new Vector3(aspect * baseScale, baseScale, 1);


    return (
        <sprite position={position} scale={scale}>
            <spriteMaterial attach="material" map={texture} transparent={true} />
        </sprite>
    );

}