import React from 'react';

type OutcomeProps = {
    passengersPerHourPerLane: number;
    traysPerHourPerLane: number;
    lanes: number;
    hiddenArrow: number | null; // State from the parent to track which arrow is hidden
    setHiddenArrow: (arrow: number | null) => void; // Function to set hiddenArrow state
    onArrowsClick: (chartType: string) => void;
};

const Outputs: React.FC<OutcomeProps> = ({
                                             passengersPerHourPerLane,
                                             traysPerHourPerLane,
                                             lanes,
                                             hiddenArrow,
                                             setHiddenArrow,
                                             onArrowsClick
                                         }) => {
    const handleArrowClick = (index: number, chartType: string) => {
        setHiddenArrow(index);
        onArrowsClick(chartType);
    };

    return (
        <div className={'outcome-container'}>
            <h1>Outputs</h1>
            <div className={'outcome-params-container'}>
                <div className={'outcomeImages'}>
                    <div className={'outcomeImage'}>
                        <img src="/security/outcome/passenger.png" alt="Passenger Image" />
                    </div>
                    <div className={'outcomeImage'}>
                        <img src="/security/outcome/tray.png" alt="Tray Image" />
                    </div>
                    <div className={'outcomeImage'}>
                        <img src="/security/outcome/lane.png" alt="Lane Image" />
                    </div>
                </div>
                <div className={'outcomeNumbers'}>
                    <div><p>{passengersPerHourPerLane}</p></div>
                    <div><p>{traysPerHourPerLane}</p></div>
                    <div><p>{lanes}</p></div>
                </div>
                <div className={'outcomeDescriptions'}>
                    <div><p>PAX/h/lane</p></div>
                    <div><p>Trays/h/lane</p></div>
                    <div><p>Lanes</p></div>
                </div>
                <div className={'outcomeArrows'}>
                    <div className={'outcomeArrow'}>
                        <img
                            src="/security/outcome/arrows.png"
                            alt="Arrows 1"
                            style={{ opacity: hiddenArrow === 0 ? 0 : 1 }} // Only hide if this arrow is clicked
                            onClick={() => handleArrowClick(0, "pax")}
                        />
                    </div>
                    <div className={'outcomeArrow'}>
                        <img
                            src="/security/outcome/arrows.png"
                            alt="Arrows 2"
                            style={{ opacity: hiddenArrow === 1 ? 0 : 1 }} // Only hide if this arrow is clicked
                            onClick={() => handleArrowClick(1, "trays")}
                        />
                    </div>
                    <div className={'outcomeArrow'}>
                        <img
                            src="/security/outcome/arrows.png"
                            alt="Arrows 3"
                            style={{ opacity: hiddenArrow === 2 ? 0 : 1 }} // Only hide if this arrow is clicked
                            onClick={() => handleArrowClick(2, "lanes")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Outputs;
