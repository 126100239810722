import React, {type MouseEvent as ReactMouseEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    addEdge,
    Background,
    BackgroundVariant,
    Connection,
    Controls,
    Edge,
    EdgeProps,
    getConnectedEdges,
    getIncomers,
    getOutgoers,
    InternalNode,
    MiniMap,
    Node as ReactFlowNode,
    OnConnect,
    OnNodeDrag,
    OnNodesDelete,
    ReactFlow,
    ReactFlowProvider,
    reconnectEdge,
    useEdgesState,
    useNodesState,
    useReactFlow,
    useStoreApi,
    Viewport,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

import {DnDProvider, useDnD} from './DnDContext';

import './index.css';
import SmartEdge from "./SmartEdge.tsx";
import {PASSENGER_RATIO} from "./DndDefaults.ts";
import DndNode from "./DndNode.tsx";
import ConfigurationPopupForm from "../common/popup/ConfigurationPopupForm.tsx";
import FloatingToolbox from "./FloatingToolbox.tsx";
import {
    componentSpecificationToNodeData,
    DndComponentSpecification,
    edgeToParameters,
    nodeToParameters,
    parametersToEdge
} from "./DndPopupUtils.ts";
import {DndPaxLabel} from "./DndPaxLabel.tsx";
import {DndPaxBoundary} from "./DndPaxBoundary.tsx";
import {ConfigurationPopupFormSpecification} from "../common/popup/common.ts";

//Define node types properly
type NodeType = 'arrival' | 'departure';

export interface NodeData {
    label: string;
    altName: string;
    color?: string;
    link?: string;
}

interface Node {
    id: string;
    type: NodeType;
    data: NodeData;
    position: { x: number; y: number };
}

const getNodeId = (): string => `dndnode_${Date.now()}`;

const nodeTypes = {
    arrival: DndNode,  // Ensure the "arrival" node is registered here
    departure: DndNode,  // Ensure the "arrival" node is registered here
};

const initialNodes: { data: { label: string, altName: string }; id: string; position: { x: number; y: number }; type: string }[] = [

];

const initialEdges: Edge[] = []


const DnDFlow: React.FC = () => {
    const edgeReconnectSuccessful = useRef(true);
    const nodePositionsRef = useRef(initialNodes);

    const reactFlowWrapper = useRef<HTMLDivElement | null>(null);

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes); //@kbaran should initial nodes be typed?
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const {screenToFlowPosition} = useReactFlow();
    const {getInternalNode, getNode } = useReactFlow();
    const store = useStoreApi();
    const MIN_DISTANCE = 150;

    const { setCenter} = useReactFlow();


    //TODO @kbaran to start with horizontal line in the middle
    useEffect(() => {
        // Adjust the view to center on the X position 400
        setCenter(400, 0, {
            zoom: 1, // Optional: zoom level (adjust to your preference)
           // duration: 800, // Optional: animation duration in milliseconds
        });
    }, [setCenter]);


    const { paxStageNode} = useDnD();

    function adjustWeights(prevEdges: Edge[], sourceNodeId: string, removedEdgeId: string = "") {
        // Find all sibling edges (edges with the same source)
        const siblingEdges = prevEdges.filter((edge) => edge.source === sourceNodeId).filter((edge) => edge.id !== removedEdgeId); // cannot this be replaced by getOutgoers?


        const totalEdges = siblingEdges.length;
        if (totalEdges === 0) return prevEdges;

        // Integer division to get whole number percentages
        const baseWeight = Math.floor(100 / totalEdges);
        const totalBaseWeight = baseWeight * totalEdges;
        const remainder = 100 - totalBaseWeight;

        // Distribute the remainder to one of the edges
        let remainderAdded = false;

        const updatedEdges = prevEdges
            .filter((edge) => edge.id !== removedEdgeId)
            .map((edge) => {
                if (edge.source === sourceNodeId) {
                    let passengerRatioInPercent = baseWeight;
                    if (!remainderAdded) {
                        passengerRatioInPercent += remainder;
                        remainderAdded = true;
                    }
                    return {
                        ...edge,
                        data: {...edge.data, passengerRatio: passengerRatioInPercent},
                        label: `${passengerRatioInPercent}%`,
                    };
                }
                return edge;
            });
        return enrichByValidatingEdges(updatedEdges, sourceNodeId);
    }

    const adjustSiblingEdgeWeights = (sourceNodeId: string, removedEdgeId: string = "") => {
        setEdges((prevEdges) => {
            return adjustWeights(prevEdges, sourceNodeId, removedEdgeId);
        });
    };


    const onConnect: OnConnect = useCallback(
        (params: Connection | Edge) => {
            //console.log("On connect", params)

            const source = getNode(params.source);
            const target = getNode(params.target);


            //TODO @kbaran extract validation to single place
            if (source?.type === 'departure' && target?.type === 'arrival') {
                //Connecting departure source to arrival target is not allowed
                //Similar validation should be implemented in other places where the nodes are connected
                return;
            }

            if (source?.id === target?.id) {
                return
            }

            const isCycle = edges.some((edge) => edge.source === target?.id && edge.target === source?.id);

            if(isCycle) {
                return
            }

            const isTransferEdge = source?.type !== target?.type;

            const newEdge = {
                ...params, data: {
                    passengerRatio: PASSENGER_RATIO, // Initialize ratio inside the data field
                    isTransferEdge: isTransferEdge
                },
            }; // Default ratio to 100%

            setEdges((eds) => addEdge(newEdge, eds))

            adjustSiblingEdgeWeights(newEdge.source);
        },
        [adjustSiblingEdgeWeights, getNode, setEdges],
    );

    const getClosestEdge = useCallback((node: ReactFlowNode) => {
        const {nodeLookup} = store.getState();
        const internalNode: InternalNode<ReactFlowNode> = getInternalNode(node.id)!; // @kbaran Not null assumption but probably  unsafe

        const closestNode: {
            distance: number;
            node: InternalNode<ReactFlowNode> | null
        } = Array.from(nodeLookup.values()).reduce(
            (res: { distance: number, node: InternalNode<ReactFlowNode> | null }, n) => {
                if (n.id !== internalNode.id) {
                    const dx =
                        n.internals.positionAbsolute.x -
                        internalNode.internals.positionAbsolute.x;
                    const dy =
                        n.internals.positionAbsolute.y -
                        internalNode.internals.positionAbsolute.y;
                    const d = Math.sqrt(dx * dx + dy * dy);

                    if (d < res.distance && d < MIN_DISTANCE) {
                        res.distance = d;
                        res.node = n;
                    }
                }

                return res;
            },
            {
                distance: Number.MAX_VALUE,
                node: null,
            },
        );

        if (!closestNode.node) {
            return null;
        }

        const closeNodeIsSource =
            closestNode.node.internals.positionAbsolute.y <
            internalNode.internals.positionAbsolute.y;

        return {
            id: closeNodeIsSource
                ? `${closestNode.node.id}-${node.id}`
                : `${node.id}-${closestNode.node.id}`,
            source: closeNodeIsSource ? closestNode.node.id : node.id,
            target: closeNodeIsSource ? node.id : closestNode.node.id,
        };
    }, [getInternalNode, store]);


    const isInsideRestrictedArea = (x: number, type: string | undefined) => {
        if (type === 'arrival') {
            return x > 250;
        } else {
            return x < 403;
        }
    };

    const onNodeDrag: OnNodeDrag<ReactFlowNode> = useCallback(
        (_: React.MouseEvent, node: ReactFlowNode) => {
            const {x} = node.position;
            // Check if the node is within the restricted area
            // If the node is in the restricted area, revert to the previous position
            if (isInsideRestrictedArea(x, node.type)) {
                const previousRef = nodePositionsRef.current.find(n => n.id === node.id);
                if (previousRef === undefined) {
                    console.log("Short quitting")
                    return
                }

                const previousPosition = previousRef.position;
                setNodes((nds) =>
                    nds.map((n) =>
                        n.id === node.id
                            ? {...n, position: previousPosition}
                            : n
                    )
                );
            } else {
                // Otherwise, store the current position as the last valid position
                nodePositionsRef.current = nodePositionsRef.current.map(n =>
                    n.id === node.id ? {...n, position: node.position} : n
                );
            }

            const closeEdge = getClosestEdge(node);

            setEdges((edges) => {
                const filteredEdges = edges.filter((e) => e.className !== 'temp');

                if (!closeEdge) return filteredEdges;

                const edgeExists = filteredEdges.some(
                    (ne) => ne.source === closeEdge.source && ne.target === closeEdge.target,
                );

                if (edgeExists) return filteredEdges;

                const source = getNode(closeEdge.source);
                const target = getNode(closeEdge.target);

                const isCycle = edges.some((edge) => edge.source === target?.id && edge.target === source?.id);

                if(isCycle) {
                    return filteredEdges
                }

                const connectFromDepartureToArrival = source?.type == 'departure' && target?.type == 'arrival';

                if (!connectFromDepartureToArrival) {
                    const isTransferEdge = source?.type !== target?.type;

                    const enrichedEdge = {
                        ...closeEdge,
                        className: 'temp',
                        data: {
                            passengerRatio: PASSENGER_RATIO,
                            isTransferEdge: isTransferEdge,
                        }
                    };

                    filteredEdges.push(enrichedEdge);
                }

                return filteredEdges;
            });

        },
        [getClosestEdge, getNode, setEdges, setNodes],
    );

    //here connection seems to be established
    const onNodeDragStop = useCallback(
        (_: React.MouseEvent, node: ReactFlowNode) => {
            //console.log("Drag stop")
            if (isInsideRestrictedArea(node.position.x, node.type)) {
                const previousNodePosition = nodePositionsRef.current.find(n => n.id === node.id);
                if (previousNodePosition === undefined) {
                    //console.log("Short quitting")
                    return
                }
                const previousPosition = previousNodePosition.position;
                setNodes((nds) =>
                    nds.map((n) =>
                        n.id === node.id
                            ? {...n, position: previousPosition}
                            : n
                    )
                );

            } else {
                // Otherwise, store the current position as the last valid position
                nodePositionsRef.current = nodePositionsRef.current.map(n =>
                    n.id === node.id ? {...n, position: node.position} : n
                );
            }
            const closeEdge = getClosestEdge(node);

            setEdges((edges) => {
                const filteredEdges = edges.filter((e) => e.className !== 'temp');

                if (!closeEdge) return filteredEdges;

                const edgeExists = filteredEdges.some(
                    (ne) => ne.source === closeEdge.source && ne.target === closeEdge.target,
                );

                if (edgeExists) return filteredEdges;

                const source = getNode(closeEdge.source);
                const target = getNode(closeEdge.target);

                const isCycle = edges.some((edge) => edge.source === target?.id && edge.target === source?.id);

                if(isCycle) {
                    return filteredEdges
                }

                const connectFromDepartureToArrival = source?.type == 'departure' && target?.type == 'arrival';

                if (!connectFromDepartureToArrival) {
                    const isTransferEdge = source?.type !== target?.type;

                    const enrichedCloseEdge = {
                        ...closeEdge,
                        data: {
                            passengerRatio: PASSENGER_RATIO,
                            isTransferEdge: isTransferEdge,
                        },
                    };

                    filteredEdges.push(enrichedCloseEdge);
                }

                return filteredEdges;
            });

            if(closeEdge) {
                adjustSiblingEdgeWeights(closeEdge?.source)
            }

        },
        [adjustSiblingEdgeWeights, getClosestEdge, getNode, setEdges, setNodes],
    );


    const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {

            event.preventDefault();

            //TODO @kbaran to be removed I think
            if (!paxStageNode.type) {
                return;
            }

            const position = screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });
            const newNode: Node = {
                id: getNodeId(),
                type: paxStageNode.type as NodeType, // @kbaran unsafe cast but we set this value in Sidebar
                position,
                data: {label: `${paxStageNode.label}`, altName: ''},
            };

            //console.log ("Adding node", newNode)

            if (isInsideRestrictedArea(newNode.position.x, paxStageNode.type)) {
                //console.log("Cannot be dropped here!", newNode)

                return;
            }

            setNodes((nds) => nds.concat(newNode));

            nodePositionsRef.current = [...nodePositionsRef.current, newNode];


        },
        [screenToFlowPosition, paxStageNode, setNodes],
    );

    function getReduce(deleted: ReactFlowNode[]) {
        return deleted.reduce((acc: Edge[], node) => {
            const incomers: ReactFlowNode[] = getIncomers(node, nodes, edges);
            const outgoers: ReactFlowNode[] = getOutgoers(node, nodes, edges);
            const connectedEdges: Edge[] = getConnectedEdges([node], edges);

            const remainingEdges: Edge[] = acc.filter(
                (edge: Edge) => !connectedEdges.includes(edge),
            );

            const createdEdges: Edge[] = incomers.flatMap(({id: source}) =>
                outgoers.map(({id: target}) => ({
                    id: `${source}->${target}`,
                    source,
                    target,
                })),
            );

            let updatedEdges = [...remainingEdges, ...createdEdges];

            // After creating the new edges, adjust weights for each source
            const sources = [...new Set(updatedEdges.map((edge) => edge.source))];
            sources.forEach((source) => {
                updatedEdges = adjustWeights(updatedEdges, source);
            });

            return updatedEdges;
        }, edges);
    }

    const onNodesDelete: OnNodesDelete<ReactFlowNode> = useCallback(
        (deleted: ReactFlowNode[]) => {
            setEdges(
                getReduce(deleted),
            );
        },
        [nodes, edges, setEdges],
    );

    const onReconnectStart = useCallback(() => {
        edgeReconnectSuccessful.current = false;
    }, []);

    const onReconnect = useCallback((oldEdge: Edge, newConnection: Connection) => {
        edgeReconnectSuccessful.current = true;
        setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
    }, [setEdges]);

    const onReconnectEnd = useCallback((_: unknown, edge: Edge) => {
        if (!edgeReconnectSuccessful.current) {
            setEdges((eds) => eds.filter((e) => e.id !== edge.id));
        }

        edgeReconnectSuccessful.current = true;
    }, [setEdges]);



    const [linePosition] = useState(400); // x position of the line in chart coordinates
    const [viewTransform, setViewTransform] = useState<Viewport>({x: 0, y: 0, zoom: 1}); // Tracks the current zoom and pan state


    const handleMove: (_: MouseEvent | TouchEvent | null, viewport: Viewport) => void = useCallback((_: MouseEvent | TouchEvent | null, transform: Viewport) => {
        // Update the current zoom and pan values
        setViewTransform(transform);
    }, []);

    // Calculate the screen position of the vertical line based on pan and zoom
    const screenLinePosition = (linePosition * viewTransform.zoom) + viewTransform.x;

    const defaultEdgeOptions = {
        type: 'smart',
        animated: true,
    }
    const edgeTypes = useMemo(() => ({
        smart: (props: EdgeProps) => (
            <SmartEdge {...props}
                       passengerRatio={typeof props.data?.passengerRatio === 'number' ? props.data.passengerRatio : PASSENGER_RATIO}  // @kbaran fix me
                       isTransferEdge={typeof props.data?.isTransferEdge === 'boolean' ? props.data?.isTransferEdge: false}
                       isValid={typeof props.data?.isValid === 'boolean' ? props.data.isValid : true}
            />
        ),
    }), []);

    function onEdgesDelete(edges: Edge[]) {
        edges.forEach(edge => {
            adjustSiblingEdgeWeights(edge.source, edge.id)
        })
    }

    function handleDoubleNodeClick(_: ReactMouseEvent, node: ReactFlowNode) {
        //TODO @kbaran fixme
        const nd: NodeData = {
            altName: typeof node.data.altName === 'string' ? node.data.altName: '',
            label: typeof node.data.label === 'string' ? node.data.label: '',
            color: typeof node.data.color === 'string' ? node.data.color: undefined
        }

        const componentSpecification = nodeToParameters(node.id, nd)

        setisComponentConfigurationVisible(true)
        setSelectedComponentSpecification(componentSpecification)
    }

    function handleDoubleEdgeClick(_: ReactMouseEvent, edge: Edge) {
        const componentSpecification = edgeToParameters(edge.id, edge)

        setisComponentConfigurationVisible(true)
        setSelectedComponentSpecification(componentSpecification)
    }

    const [isComponentConfigurationVisible, setisComponentConfigurationVisible] = useState(false);
    const [selectedComponentSpecification, setSelectedComponentSpecification] = useState<DndComponentSpecification | undefined>();

    function enrichByValidatingEdges(edgesUpdatedWithNewSpecification: Edge[], parentSourceId: string) {
        const edgesBelongingToSourceNode = edgesUpdatedWithNewSpecification.filter((edge) => edge.source === parentSourceId);

        const totalPassengerRatio = edgesBelongingToSourceNode.reduce((acc, edge) => acc + Number(edge.data?.passengerRatio), 0); //TODO @kbaran ugly casting

        if (totalPassengerRatio !== 100) {

            //update edge data and pass isValid: false

            return edgesUpdatedWithNewSpecification.map((edge) => {
                if (edge.source === parentSourceId) {
                    return {
                        ...edge,
                        data: {
                            ...edge.data,
                            isValid: false
                        }
                    }
                }
                return edge
            })
        } else {
            return edgesUpdatedWithNewSpecification.map((edge) => {
                if (edge.source === parentSourceId) {
                    return {
                        ...edge,
                        data: {
                            ...edge.data,
                            isValid: true
                        }
                    }
                }
                return edge
            })
        }
    }


    function updateComponentSpecification(newSpecification: ConfigurationPopupFormSpecification) {
        //TODO @kbaran figure out whether we update node or edge
        const isEdge = edges.find(e => e.id === newSpecification.componentId)
        if(isEdge) {
            setEdges((eds) => {
                const parentSourceId = isEdge.source;
                const edgesUpdatedWithNewSpecification = eds.map((e) => {
                    if (e.id === newSpecification.componentId) {
                        const popupData = parametersToEdge(newSpecification)
                        return {
                            ...e,
                            data: {
                                ...e.data,
                                passengerRatio: popupData.edgeData.passengerRatio,
                                walkingTime: popupData.edgeData.walkingTime
                            }
                        }
                    }
                    return e
                });

                return enrichByValidatingEdges(edgesUpdatedWithNewSpecification, parentSourceId);
            })

        } else {
            setNodes((nds) => nds.map((n) => {
                const newNodeData = componentSpecificationToNodeData(newSpecification)

                if (n.id === newSpecification.componentId) {

                    return {
                        ...n,
                        data: {
                            ...n.data,
                            altName: newNodeData.nodeData.altName,
                            color: newNodeData.nodeData.color
                        }
                    }
                }
                return n
            }))
        }


    }

    function displayComponentConfiguration() {

        return <>
            <ConfigurationPopupForm
                initialData={selectedComponentSpecification?.componentSpecification}
                onSubmit={(newComponentSpec: ConfigurationPopupFormSpecification) => {
                    updateComponentSpecification(newComponentSpec);
                    setisComponentConfigurationVisible(false);
                    setSelectedComponentSpecification(undefined);

                }}
                onClose={() => {
                    setisComponentConfigurationVisible(false)
                }}
            />
        </>;
    }

    //console.log(nodes)
    console.log('Edges: ', edges)

    return (
        <div className="dndflow">
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onEdgesDelete={onEdgesDelete}
                    onNodeDrag={onNodeDrag}
                    onNodeDragStop={onNodeDragStop}
                    onConnect={onConnect}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    onNodesDelete={onNodesDelete}
                    onReconnect={onReconnect}
                    onReconnectStart={onReconnectStart}
                    onReconnectEnd={onReconnectEnd}
                    fitView={false}
                    onMove={handleMove}
                    edgeTypes={edgeTypes}
                    defaultEdgeOptions={defaultEdgeOptions}
                    nodeTypes={nodeTypes}
                    onNodeDoubleClick={handleDoubleNodeClick}
                    onEdgeDoubleClick={handleDoubleEdgeClick}
                >
                    <Controls/>
                    <MiniMap/>
                    <Background variant={BackgroundVariant.Dots} gap={12} size={1}/>
                    <FloatingToolbox passengerFlow={'arrival'}></FloatingToolbox>

                    <DndPaxLabel label={'ARRIVALS'} position={screenLinePosition - 300} />
                    <DndPaxLabel label={'DEPARTURES'} position={screenLinePosition + 230} />
                    <DndPaxBoundary position={screenLinePosition} />

                    <FloatingToolbox passengerFlow={'departure'}></FloatingToolbox>
                </ReactFlow>
                {isComponentConfigurationVisible  &&
                    displayComponentConfiguration()}
            </div>

        </div>
    );
};

const
    DnDComponent: React.FC = () => (
    <ReactFlowProvider>
        <DnDProvider>
            <DnDFlow/>
        </DnDProvider>
    </ReactFlowProvider>
);

export default DnDComponent;