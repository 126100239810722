import {useEffect, useState} from 'react';
import './styles/App.css';
import {Canvas} from '@react-three/fiber';
import {OrbitControls } from '@react-three/drei';
import PlaneWithTexture from "./components/security/PlaneWithTexture.tsx";
import Security, {SecurityProps} from "./components/security/Security.tsx";
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from 'react-router-dom';
import routes from './routes.ts';
import ConfigurationPopupForm from "./components/common/popup/ConfigurationPopupForm.tsx";
import {ProcessThroughput,} from "./domain/common.ts";
// import ComponentsChart from "./components/security/ComponentsChart.tsx";
import AirportOverviewPage from "./components/airport/AirportOverviewPage.tsx";
import WizardView from "./components/wizard/WizardView.tsx";
import DnDView from "./components/dnd/DnDView.tsx";
// import PotentialAchieved from "./components/security/PotentialAchieved.tsx";
import * as config from "./config/Config.tsx"
import {
    inputSpecification,
    inputToComponentId,
    inputToSubprocessesId,
    peakHourPassengerDemandSpecification
} from "./config/Config.tsx"
import Navigation from "./components/common/Navigation.tsx";
// import Variables from "./components/security/Variables.tsx";
import Outputs from "./components/security/Outputs.tsx";
import Inputs from "./components/common/Inputs.tsx";
import Logo from "./components/common/Logo.tsx";
import PaxThroughputChart from "./components/security/charts/PaxThroughputChart.tsx";
import {
    recalculateBeltUncappedThroughputForZone,
    recalculateBodyScannerUncappedThroughputForZone,
    recalculateClearLaneUncappedThroughputForZone,
    recalculateDivestmentUncappedThroughputForZone,
    recalculateFemaleBodySearchUncappedThroughputForZone,
    recalculateMaleBodySearchUncappedThroughputForZone,
    recalculateRecirculatedTraysUncappedThroughputForZone,
    recalculateRejectLaneUncappedThroughputForZone,
    recalculateRejectStationsUncappedThroughputForZone,
    recalculateRepackUncappedThroughputForZone,
    recalculateXRayUncappedThroughputForZone
} from "./functions/throughputCalculations.ts";
import PassportControlZone from "./components/passport_control/PassportControlZone.tsx";
import {ConfigurationPopupFormSpecification} from "./components/common/popup/common.ts";
import {isInputForBottleneckSubprocess} from "./functions/common.tsx";
import TrayThroughputChart from "./components/security/charts/TrayThroughputChart.tsx";
import InfinityFloor from "./components/common/floor/InfinityFloor.tsx";

function App() {

    const [zoneInputSpecification, setZoneInputSpecification] = useState<Record<string, ConfigurationPopupFormSpecification[]>>(config.inputSpecification);
    const [demandSpecification, setDemandSpecification] = useState<ConfigurationPopupFormSpecification>(peakHourPassengerDemandSpecification)
    const [terminalZonesProcessesThroughput, setTerminalZonesProcessesThroughput] = useState<Record<string, ProcessThroughput[]>>(config.subprocessThroughput);
    const [isComponentConfigurationVisible, setisComponentConfigurationVisible] = useState(false);
    const [clickedZoneInputId, setClickedZoneInputId] = useState<string | null>(null)
    const [hoveredInputId, setHoveredInputId] = useState<string | null>(null)
    const [clickedModelId, setClickedModelId] = useState<string | null>(null)
    const [hoveredComponentId, setHoveredComponentId] = useState<string | null>(null)
    const [inputsForBottleneckProcess, setInputsForBottleneckProcess] = useState<string[]>([]);
    const [componentsForBottleneckSubprocess, setComponentsForBottleneckSubprocess] = useState<string[]>([]);
    const [peakHourPassengerDemand, setPeakHourPassengerDemand] = useState<number>(5000);
    const [isDemandClicked, setIsDemandClicked] = useState<boolean>(false)
    const [currentZone, setCurrentZone] = useState<string | undefined>()
    const [selectedInputSpecification, setSelectedInputSpecification] = useState<ConfigurationPopupFormSpecification | undefined>();
    const [hoveredComponents, setHoveredComponents] = useState<string[] | null>(null);

    //chart
    const [visibleChart, setVisibleChart] = useState<string | null>(null);
    const [hiddenArrow, setHiddenArrow] = useState<number | null>(null);

    //output
    const [passengersPerHourPerLane, setPaxPerHourPerLane] = useState<number>(0);
    const [traysPerHourPerLane, setTraysPerHourPerLane] = useState<number>(0);
    const [lanes, setLanes] = useState<number>(0);



    useEffect(() => {
        if (currentZone === "security") {
            const uncappedBeltThroughputInt = Math.floor(recalculateBeltUncappedThroughputForZone(currentZone, zoneInputSpecification));
            const uncappedXRayThroughputInt = Math.floor(recalculateXRayUncappedThroughputForZone(currentZone, zoneInputSpecification));
            const uncappedRecirculatedTraysThroughputInt = Math.floor(recalculateRecirculatedTraysUncappedThroughputForZone(currentZone, zoneInputSpecification));
            const uncappedDivestmentThroughputInt = Math.floor(recalculateDivestmentUncappedThroughputForZone(currentZone, zoneInputSpecification));
            const uncappedBodyScannerThroughputInt = Math.floor(recalculateBodyScannerUncappedThroughputForZone(currentZone, zoneInputSpecification));
            const uncappedFemaleBodySearchThroughputInt = Math.floor(recalculateFemaleBodySearchUncappedThroughputForZone(currentZone, zoneInputSpecification));
            const uncappedMaleBodySearchThroughputInt = Math.floor(recalculateMaleBodySearchUncappedThroughputForZone(currentZone, zoneInputSpecification));
            const uncappedRejectLaneThroughputInt = Math.floor(recalculateRejectLaneUncappedThroughputForZone(currentZone, zoneInputSpecification));
            const uncappedRejectStationsThroughputInt = Math.floor(recalculateRejectStationsUncappedThroughputForZone(currentZone, zoneInputSpecification));
            const uncappedClearLaneThroughputInt = Math.floor(recalculateClearLaneUncappedThroughputForZone(currentZone, zoneInputSpecification));
            const uncappedRepackThroughputInt = Math.floor(recalculateRepackUncappedThroughputForZone(currentZone, zoneInputSpecification));


            const paxPerHourPerLane = Math.min(
                uncappedBeltThroughputInt,
                uncappedXRayThroughputInt,
                uncappedRecirculatedTraysThroughputInt,
                uncappedDivestmentThroughputInt,
                uncappedBodyScannerThroughputInt,
                uncappedFemaleBodySearchThroughputInt,
                uncappedMaleBodySearchThroughputInt,
                uncappedRejectLaneThroughputInt,
                uncappedRejectStationsThroughputInt,
                uncappedClearLaneThroughputInt,
                uncappedRepackThroughputInt
            );

            setPaxPerHourPerLane(paxPerHourPerLane);

            const traySpec = zoneInputSpecification[currentZone].find(input => input.componentId === "tray");
            const traysPerPassenger = traySpec?.parameters.find(param => param.id === "trays_per_passenger")?.value as number
            const traysPerHourPerLane = Math.floor(paxPerHourPerLane * traysPerPassenger)
            setTraysPerHourPerLane(traysPerHourPerLane);

            setTerminalZonesProcessesThroughput((prevThroughput) => {
                const updatedThroughput = { ...prevThroughput };

                updatedThroughput[currentZone] = updatedThroughput[currentZone].map(subprocess => {
                    let uncappedThroughput = 0;
                    switch (subprocess.id) {
                        case "belt":
                            uncappedThroughput = uncappedBeltThroughputInt;
                            break;
                        case "x_ray":
                            uncappedThroughput = uncappedXRayThroughputInt;
                            break;
                        case "recirculated_trays":
                            uncappedThroughput = uncappedRecirculatedTraysThroughputInt;
                            break;
                        case "divestment_stations":
                            uncappedThroughput = uncappedDivestmentThroughputInt;
                            break;
                        case "body_scanner":
                            uncappedThroughput = uncappedBodyScannerThroughputInt;
                            break;
                        case "body_search_female":
                            uncappedThroughput = uncappedFemaleBodySearchThroughputInt;
                            break;
                        case "body_search_male":
                            uncappedThroughput = uncappedMaleBodySearchThroughputInt;
                            break;
                        case "reject_lane":
                            uncappedThroughput = uncappedRejectLaneThroughputInt;
                            break;
                        case "reject_stations":
                            uncappedThroughput = uncappedRejectStationsThroughputInt;
                            break;
                        case "clear_lane":
                            uncappedThroughput = uncappedClearLaneThroughputInt;
                            break;
                        case "re_pack":
                            uncappedThroughput = uncappedRepackThroughputInt;
                            break;
                        default:
                            uncappedThroughput = 0;
                    }


                    const paxPotential = Math.floor(uncappedThroughput - paxPerHourPerLane)
                    const traysPotential = Math.floor(paxPotential * traysPerPassenger)

                    const isBottleneck = uncappedThroughput === paxPerHourPerLane;

                    return {
                        ...subprocess,
                        paxPerHourPerLane: paxPerHourPerLane,
                        traysPerHourPerLane: traysPerHourPerLane,
                        paxPotential: paxPotential,
                        traysPotential: traysPotential,
                        isBottleneck: isBottleneck
                    };
                });

                return updatedThroughput;
            });
        }
    }, [zoneInputSpecification, currentZone]);

    useEffect(() => {
        if (passengersPerHourPerLane > 0) {
            console.log("Recalculating lanes peakHourPassengerDemand: " + peakHourPassengerDemand  + "passengersPerHourPerLane " + passengersPerHourPerLane)
            const recalculatedLanes = Math.ceil(peakHourPassengerDemand / passengersPerHourPerLane);
            setLanes(recalculatedLanes);
        }
    }, [peakHourPassengerDemand, passengersPerHourPerLane]);

    //recalculate hovered components when hovered input changes
    useEffect(() => {
        if (hoveredInputId && currentZone) {
            // Set hovered components based on the inputId
            const hoveredComponentIds = inputToComponentId[currentZone][hoveredInputId] || [];
            setHoveredComponents(hoveredComponentIds);
        } else {
            setHoveredComponents(null);  // Reset when there is no hovered input
        }

    }, [hoveredInputId]);


    //recalculate hovered components and input when hovered component changes
    useEffect(() => {
        //TODO TK use precalculated componentToInputId instead
        if (hoveredComponentId && currentZone) {
            // Find the matching key in inputToComponentId where the hoveredComponentId is part of the array
            const matchedInput = Object.keys(inputToComponentId[currentZone]).find(inputId =>
                inputToComponentId[currentZone][inputId].includes(hoveredComponentId)
            );

            // If a match is found, set both hoveredComponents and hoveredInput
            if (matchedInput) {
                setHoveredComponents(inputToComponentId[currentZone][matchedInput]);  // Set all elements from the array
                setHoveredInputId(matchedInput);  // Set the corresponding key as the hovered input
            } else {
                setHoveredComponents(null);  // Reset if no match is found
                setHoveredInputId(null);       // Reset hovered input
            }
        } else {
            setHoveredComponents(null);  // Reset if hoveredComponentId is null
            setHoveredInputId(null);       // Reset hovered input
        }
    }, [hoveredComponentId]);


    // Recalculate bottleneck inputs whenever terminalZonesProcessesThroughput changes
    useEffect(() => {
        if (currentZone && terminalZonesProcessesThroughput) {
            const recalculatedBottleneckInputs = inputSpecification[currentZone].filter(inputSpec =>
                isInputForBottleneckSubprocess(inputSpec.componentId, terminalZonesProcessesThroughput[currentZone], inputToSubprocessesId[currentZone])
            ).map(inputSpec => inputSpec.componentId);

            setInputsForBottleneckProcess(recalculatedBottleneckInputs);
        }
    }, [terminalZonesProcessesThroughput, currentZone]);


    useEffect(() => {
        if (currentZone) {
        const recalculatedBottleneckComponents: string[] = inputsForBottleneckProcess.flatMap(inputId =>
            inputToComponentId[currentZone][inputId] || []
        );
        setComponentsForBottleneckSubprocess(recalculatedBottleneckComponents);
    }}, [inputsForBottleneckProcess]);

    // const [hoveredComponents, setHoveredComponents] = useState<string | undefined>(undefined);



    function makeFormVisible() {
        setisComponentConfigurationVisible(true);
    }

    //TODO TK recalculate on backend an set it here according to the response
    // function recalculateThroughput() {
    //     setTerminalZonesProcessesThroughput(prevState => prevState)
    //     console.log("Recalculating throughput")
    // }

    // TODO TK Implement this function
    function updateInputSpecification(newSpecification: ConfigurationPopupFormSpecification) {
        if (!currentZone) return;

        if (newSpecification.componentId === "peak_hour_passenger_demand") {
            setDemandSpecification(newSpecification);
            const demandValue = newSpecification.parameters.find(param => param.id === "value")?.value as number
            setPeakHourPassengerDemand(demandValue);
        } else {
            // Otherwise, update the zone input specification as usual
            setZoneInputSpecification((previousSpecifications) => {
                const updatedSpecifications = previousSpecifications[currentZone].map((componentSpecification) => {
                    if (componentSpecification.componentId === newSpecification.componentId) {
                        return { ...componentSpecification, parameters: newSpecification.parameters };
                    }
                    return componentSpecification;
                });

                return {
                    ...previousSpecifications,
                    [currentZone]: updatedSpecifications,
                };
            });
        }

        console.log("Input specification after changing specification:" + JSON.stringify(zoneInputSpecification));
        // TODO TK recalculating throughput on submit not here
        // recalculateThroughput()
    }

    // function updateVariable(name: string, value: number) {
    //     if (!currentZone) return;
    //     setTerminalZonesVariables((prevVariables) =>  {
    //         const updatedVariables = prevVariables[currentZone].map((variable) => {
    //             if(variable.name === name)  {
    //                 return {...variable,  value: value};
    //             }
    //             return variable;
    //         })
    //         return {
    //             ...prevVariables,
    //             [currentZone]: updatedVariables
    //         }
    //     } )
    //     console.log("Components specification after changing specification:" + JSON.stringify(terminalZonesVariables));
    //     recalculateThroughput()
    // }

    useEffect(() => {
        if (currentZone && clickedModelId) {
            setIsDemandClicked(false)
            const matchedInputId = Object.keys(inputToComponentId[currentZone]).find(inputId =>
                inputToComponentId[currentZone][inputId].includes(clickedModelId)
            );

            if (matchedInputId) {
                const currentZoneInputSpecifications = zoneInputSpecification[currentZone];
                const inputSpecification = currentZoneInputSpecifications?.find(c => c.componentId === matchedInputId);
                setSelectedInputSpecification(inputSpecification);
            }
            makeFormVisible();
        }
    }, [clickedModelId, currentZone]);

    useEffect(() => {
        if (currentZone && clickedZoneInputId) {
            setIsDemandClicked(false)
            const currentZoneInputSpecifications = zoneInputSpecification[currentZone];
            const inputSpecification = currentZoneInputSpecifications?.find(c => c.componentId === clickedZoneInputId);
            setSelectedInputSpecification(inputSpecification);
            makeFormVisible()
        }
    }, [clickedZoneInputId]);


    useEffect(() => {
        if (isDemandClicked) {
            setClickedZoneInputId(null)
            setClickedModelId(null)
            setSelectedInputSpecification(demandSpecification)
            makeFormVisible()
        }
    }, [isDemandClicked]);

    // useEffect(() => {
    //     console.log("Sending data to server to recalculate model...");
    //     console.log("Components:", JSON.stringify(zoneInputSpecification, null, 2));
    //     console.log("Peak hour passenger demand " + peakHourPassengerDemand);
    // }, [zoneInputSpecification, peakHourPassengerDemand]);


    //once form visible change and freeze the background
    useEffect(() => {
        const body = document.body;
        if (isComponentConfigurationVisible) {
            body.classList.add('no-scroll');
        } else {
            body.classList.remove('no-scroll');
        }
        return () => {
            body.classList.remove('no-scroll');
        };
    }, [isComponentConfigurationVisible]);


    function displayInputConfiguration() {
        return <>
            <ConfigurationPopupForm
                initialData={selectedInputSpecification}
                onSubmit={(newComponentSpec: ConfigurationPopupFormSpecification) => {
                    updateInputSpecification(newComponentSpec);
                    setisComponentConfigurationVisible(false);
                    setSelectedInputSpecification(undefined);
                    setClickedModelId(null)
                    setClickedZoneInputId(null)
                    setIsDemandClicked(false)
                }}
                onClose={() => {
                    setisComponentConfigurationVisible(false);
                    setSelectedInputSpecification(undefined);
                    setClickedModelId(null)
                    setClickedZoneInputId(null)
                    setIsDemandClicked(false)
                }}
            />
        </>;
    }

    function createRoutes() {
        return <div style={{width: "100%", height: "100%"}}>
            <Routes>
                {/*by default route to airport view*/}
                <Route path={routes.home} element={<Navigate to="/airport" replace/>}/>
                <Route path={routes.airport} element={<AirportOverviewPage/>}/>
                <Route path={routes.security}
                       element={<SecurityControl makeFormVisible={makeFormVisible}
                                                 setClickedComponentId={setClickedModelId}
                                                 setPanelName={setCurrentZone}
                                                 hoveredComponents={hoveredComponents}
                                                 setHoveredComponentId={setHoveredComponentId}
                                                 componentsForBottleneckSubprocess={componentsForBottleneckSubprocess}
                       />}
                />
                <Route path={routes.passport}
                       element={<PassportControl makeFormVisible={makeFormVisible}
                                                 setClickedComponentId={setClickedModelId}
                                                 setPanelName={setCurrentZone}
                                                 hoveredComponents={hoveredComponents}
                                                 setHoveredComponentId={setHoveredComponentId}
                                                 componentsForBottleneckSubprocess = {componentsForBottleneckSubprocess}
                       />}
                />

                {/*<Route path={routes.checkin} element={<PassportControl/>}/>*/}
                {/*<Route path={routes.baggageDropOff} element={<PassportControl/>}/>*/}
                {/*<Route path={routes.boarding} element={<PassportControl/>}/>*/}
                {/*<Route path={routes.customs} element={<PassportControl/>}/>*/}
                <Route path={routes.wizard} element={<MainPage/>}/>
                <Route path={routes.dnd} element={<DnDView/>}/>
            </Routes>
        </div>;
    }

    return (
        <div className={'zone-container'}>
            <Router>
                {currentZone &&
                    <div className={'panel'}>
                        <Logo/>
                        <Inputs
                            inputSpecifications={inputSpecification[currentZone]}
                            setClickedInputId={setClickedZoneInputId}
                            hoveredInputId={hoveredInputId}
                            setHoveredInputId={setHoveredInputId}
                            inputsForBottleneckProcess={inputsForBottleneckProcess}
                            makeIsDemandClicked={() => setIsDemandClicked(true)}
                        />
                        <div className="widget outcome-with-chart">
                            <Outputs
                                passengersPerHourPerLane={passengersPerHourPerLane}
                                traysPerHourPerLane={traysPerHourPerLane}
                                lanes={lanes}
                                hiddenArrow={hiddenArrow}
                                setHiddenArrow={setHiddenArrow}
                                onArrowsClick={setVisibleChart}
                            />

                            {visibleChart === "pax" && (
                                <PaxThroughputChart
                                    throughputs={terminalZonesProcessesThroughput[currentZone]}
                                    onArrowsClick={() => {
                                        setVisibleChart(null);
                                        setHiddenArrow(null);
                                    }}
                                />
                            )}

                            {visibleChart === "trays" && (
                                <TrayThroughputChart
                                    throughputs={terminalZonesProcessesThroughput[currentZone]}
                                    onArrowsClick={() => {
                                        setVisibleChart(null);
                                        setHiddenArrow(null);
                                    }}
                                />
                            )}
                        </div>
                    </div>}
                <div className={'graphic-container'}>
                    <NavbarWrapper/>
                    {createRoutes()}
                    {isComponentConfigurationVisible && selectedInputSpecification &&
                        displayInputConfiguration()}
                </div>
            </Router>
        </div>
    );
}

function NavbarWrapper() {
    const location = useLocation();
    const routesWithoutNavbar = [routes.dnd, routes.security];
    const renderNavbar = !routesWithoutNavbar.includes(location.pathname);

    return renderNavbar ? <Navigation/> : null;
}

function MainPage() {
    const [isWizardOpen, setIsWizardOpen] = useState(true);

    const handleClose = () => {
        setIsWizardOpen(false);
        console.log("Wizard closed");
    };

    const handleFinish = () => {
        setIsWizardOpen(false);
        console.log("Wizard finished");
        // You can also perform additional actions here, such as saving data
    };

    const handleOpen = () => {
        setIsWizardOpen(true); // Open the wizard
    };


    return (
        <div>
            {!isWizardOpen && (
                <button onClick={handleOpen} className="open-wizard-button">
                    Open Wizard
                </button>
            )}
            {isWizardOpen && (
                <WizardView
                    onClose={handleClose}
                    onFinish={handleFinish}
                />
            )}
        </div>
    );

}

function SecurityControl({
                             makeFormVisible,
                             setClickedComponentId,
                             setPanelName,
                             hoveredComponents,
                             setHoveredComponentId,
                             componentsForBottleneckSubprocess
                         }: SecurityProps) {
    //@kbaran Logarithmic buffer needed to avoid z fighting on floor. It is not needed for the rest of the scene
    return (<>
            <Canvas shadows camera={{position: [20, 20, 20], fov: 100}} gl={{logarithmicDepthBuffer: true}}>
                {/*Extract lighting*/}
                <ambientLight intensity={1}/>
                <directionalLight
                    position={[10, 25, 10]}
                    intensity={7}
                    castShadow
                    shadow-mapSize-width={2048}
                    shadow-mapSize-height={2048}
                    shadow-camera-far={100}       // Increase far to cover more distance
                    shadow-camera-near={0.5}      // Near should be low enough to cover close objects
                    shadow-camera-left={-50}      // Larger left/right bounds
                    shadow-camera-right={50}
                    shadow-camera-top={50}
                    shadow-camera-bottom={-50}    // Larger top/bottom bounds
                />
                <Security makeFormVisible={makeFormVisible} setClickedComponentId={setClickedComponentId}
                          setPanelName={setPanelName} hoveredComponents={hoveredComponents}
                          setHoveredComponentId={setHoveredComponentId}
                          componentsForBottleneckSubprocess={componentsForBottleneckSubprocess}
                />
                <InfinityFloor/>
                <OrbitControls/>
            </Canvas>
        </>
    );
}

function PassportControl({
                             makeFormVisible,
                             setClickedComponentId,
                             setPanelName,
                             hoveredComponents,
                             setHoveredComponentId
                             //TODO TK use generic Props for zone here
                         }: SecurityProps) {
    return (<>
            <Canvas camera={{position: [20, 20, 20], fov: 100}}>
            <ambientLight intensity={5}/>
                <PassportControlZone makeFormVisible={makeFormVisible} setClickedComponentId={setClickedComponentId}
                                     setPanelName={setPanelName} hoveredComponents={hoveredComponents}
                                     setHoveredComponentId={setHoveredComponentId}
                />
                <PlaneWithTexture/>
                <OrbitControls/>
            </Canvas>
        </>
    );
}


export default App;
