import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, ChartOptions } from 'chart.js';
import { ProcessThroughput } from "../../../domain/common.ts";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface TrayThroughputChartProps {
    throughputs: ProcessThroughput[];
    onArrowsClick: () => void
    // onHover: (componentName: string | undefined) => void;
}

const TrayThroughputChart: React.FC<TrayThroughputChartProps> = ({ throughputs, onArrowsClick: onArrowsClick }) => {

    const chartRef = useRef<ChartJS<"bar"> | null>(null);
    const componentNames: (string | string[])[] = [];
    const uncappedThroughputs: number[] = [];
    const potentials: number[] = [];
    const backgroundColors: string[] = [];
    const borderColors: string[] = [];
    const containerRef = useRef<HTMLDivElement | null>(null);

    let maxThroughput = 1000; // Start with 1000 as the base maximum

    // TODO Confirm with Tomek that we don't need the components to be hovered once mouse over the bar

    // useEffect(() => {
    //     const handleMouseMove = (event: MouseEvent) => {
    //         if (chartRef.current && chartRef.current.canvas) {
    //             const chartRect = chartRef.current.canvas.getBoundingClientRect();
    //             const isInsideChart =
    //                 event.clientX >= chartRect.left &&
    //                 event.clientX <= chartRect.right &&
    //                 event.clientY >= chartRect.top &&
    //                 event.clientY <= chartRect.bottom;
    //
    //             if (!isInsideChart) {
    //                 onHover(undefined); // Reset hover state when mouse is outside chart
    //             }
    //         }
    //     };
    //
    //     document.addEventListener('mousemove', handleMouseMove);
    //
    //     return () => {
    //         document.removeEventListener('mousemove', handleMouseMove);
    //     };
    // }, []);

    throughputs.forEach((pt) => {
        const potential = pt.traysPotential || 0;

        // Split only at the first space and keep the rest together
        const labelParts = pt.displayName.split(" ", 2);
        const label = labelParts.length === 2
            ? [labelParts[0], pt.displayName.substring(labelParts[0].length + 1)]
            : [pt.displayName];

        componentNames.push(label);
        uncappedThroughputs.push(pt.traysPerHourPerLane);
        potentials.push(potential);

        if (pt.isBottleneck) {
            backgroundColors.push('#960000');
            borderColors.push('#960000');
        } else {
            backgroundColors.push('#b6b6b6');
            borderColors.push('#ffffff');
        }
        if (potential > maxThroughput) {
            maxThroughput = potential + 100;
        }
    });

    const data = {
        labels: componentNames,
        datasets: [
            {
                label: 'Throughput',
                data: uncappedThroughputs,
                backgroundColor: backgroundColors,
                borderColor: borderColors,
                borderWidth: 2,
                barThickness: 30,
                maxBarThickness: 30,
            },
            {
                label: 'Potential Throughput',
                data: potentials,
                backgroundColor: 'transparent',
                borderColor: '#ffffff',
                borderWidth: 2,
                // TODO TK dash doesn't work - to fix - similar issue - https://github.com/chartjs/Chart.js/issues/6686
                borderDash: [5, 5],
                barThickness: 30,
                maxBarThickness: 30,
            },
        ],
    };

    const options: ChartOptions<'bar'> = {
        indexAxis: 'x',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                font: {
                    size: 20,
                    weight: 'bold',
                },
                color: '#ffffff',
            }
        },
        scales: {
            x: {
                stacked: true,
                beginAtZero: true,
                max: maxThroughput,
                ticks: {
                    stepSize: 100,
                    color: '#ffffff',
                    font: {
                        size: 10,
                    },
                    align: 'center',
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90,
                },
                grid: {
                    display: false,
                },
                border: {
                    color: '#ffffff',
                    width: 3
                },
            },
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: 'Trays/h/lane',
                    color: '#ffffff',
                    font: {
                        size: 12,
                    },
                },
                ticks: {
                    display: false
                },
                grid: {
                    display: false,
                },
                border: {
                    color: '#ffffff',
                    width: 3
                },
            },
        },
    };

    return (
        <div className={`chart-container`} ref={containerRef}>
            <Bar ref={chartRef} data={data} options={options} />
            <div className={'top-right-arrows'}>
                <img src="/security/chart/reverse_arrows.png" alt="Arrows 4" onClick={onArrowsClick}/>
            </div>
        </div>
    );
};

export default TrayThroughputChart;
