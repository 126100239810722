import React from 'react';
import '../../styles/App.css';

type PeakHourPassengerDemandProps = {
    makeIsDemandClicked: () => void,  // Correct function prop
}

const PeakHourPassengerDemand: React.FC<PeakHourPassengerDemandProps> = ({ makeIsDemandClicked }) => {
    return (
        <button className='generic-button' onClick={makeIsDemandClicked}>
            Demand
        </button>
    );
};

export default PeakHourPassengerDemand;
