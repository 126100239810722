import AirportOverviewSidebar, {TerminalPartData} from "./AirportOverviewSidebar.tsx";
import {Canvas} from "@react-three/fiber";
import AirportOverviewModel from "./AirportOverview.tsx";
import PlaneWithTexture from "../security/PlaneWithTexture.tsx";
import {OrbitControls} from "@react-three/drei";
import {useState} from "react";
import routes from "../../routes.ts";

export default function AirportOverviewPage() {

    const [parts, setParts] = useState<TerminalPartData[]>([
        {
            id: 1,
            route: routes.checkin,
            name: "Check-in",
            isHovered: false,
            position: [-30, -1.25, 10],
            boxGeo: [4, 2, 4]
        },
        {
            id: 2,
            route: routes.baggageDropOff,
            name: "Baggage Drop Off",
            isHovered: false,
            position: [-5, -1.25, 10],
            boxGeo: [4, 2, 4]
        },
        {
            id: 3,
            route: routes.boarding,
            name: "Boarding",
            isHovered: false,
            position: [-30, -1.25, -10],
            boxGeo: [4, 2, 4]
        },
        {
            id: 4,
            route: routes.customs,
            name: "Customs",
            isHovered: false,
            position: [-5, -1.25, -10],
            boxGeo: [4, 2, 4]
        },
        {
            id: 5,
            route: routes.security,
            name: "Security Control",
            isHovered: false,
            position: [20, -1.25, 10],
            boxGeo: [4, 2, 4]
        },
        {
            id: 6,
            route: routes.passport,
            name: "Passport Control",
            isHovered: false,
            position: [20, -1.25, -10],
            boxGeo: [8, 2, 4]
        }
    ])

    const handleHover = (id: number, isHovered: boolean) => {
        setParts((prevParts) =>
            prevParts.map((part) =>
                part.id === id ? { ...part, isHovered } : part
            )
        );
    };

    //logarithmicDepthBuffer to combat z-fighting and flickering of the image
    return (<>
            <AirportOverviewSidebar parts={parts} onHover={handleHover}/>

            <Canvas camera={{position: [35, 65, 80], fov: 100}}
                    gl={{logarithmicDepthBuffer: true}}>
                <AirportOverviewModel onHover={handleHover} parts={parts}/>
                <PlaneWithTexture/>
                <OrbitControls autoRotate={true} autoRotateSpeed={0.2}/>
            </Canvas>
        </>
    );
}