export const LABELS = {

    POPUPS: {
        PASSENGER_RATIO: 'Passenger Ratio [%]',
        WALKING_TIME: 'Walking Time [mm:ss]',
        ALT_NAME: 'Name',
        EDGE_POPUP_LABEL: "Edge",
        COLOR: "Colour",
        DASHBOARD_LINK_LABEL: "Dashboard",
    }
}