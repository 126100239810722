import {createContext, ReactNode, useContext, useState} from 'react';

export interface PaxStageNode {
    type: string;
    stage: string;
    label: string;

}

export interface DndContextValue {
    paxStageNode: PaxStageNode;
    setPaxStageNode: (node: PaxStageNode) => void;
}

export interface DndProviderProps {
    children: ReactNode;
}


const DnDContext = createContext<DndContextValue>({} as DndContextValue);

export const DnDProvider = ({ children }: DndProviderProps) => {
    const [paxStageNode, setPaxStageNode] = useState<PaxStageNode>({type: '', stage: '', label: ''});


    return (
        <DnDContext.Provider value={{ paxStageNode, setPaxStageNode  }}>
            {children}
        </DnDContext.Provider>
    );
}

export default DnDContext;

export const useDnD = () => {
    return useContext(DnDContext);
}