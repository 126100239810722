import {TextureLoader, RepeatWrapping, NearestFilter, DoubleSide} from 'three';
import { useLoader } from '@react-three/fiber';

function PlaneWithTexture() {

    // Load the texture
    const texture = useLoader(TextureLoader, '/tile.png');
    const planeSize = 220;


    // Set the texture wrapping and repeat
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.magFilter = NearestFilter;

    const repeats = planeSize / 2;
    texture.repeat.set( repeats, repeats );


    return (
        <mesh rotation={[-Math.PI / 2, 0, 0]}>
            <planeGeometry args={[planeSize, planeSize]} />
            <meshBasicMaterial map={texture} side={DoubleSide}/>
        </mesh>
    );
}

export default  PlaneWithTexture;