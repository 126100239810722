const routes: {
    boarding: string;
    customs: string;
    baggageDropOff: string;
    checkin: string;
    security: string;
    home: string;
    airport: string;
    passport: string;
    wizard: string;
    dnd: string;
    immigration: string;
    reclaim: string;
    arrivalsHall: string;
    departureLounge: string;
    emigration: string;
    gate: string;
} = {
    baggageDropOff: "/baggage",
    boarding: "/boarding",
    checkin: "/checkin",
    customs: "/customs",
    home: '/',
    security: '/security',
    airport: '/airport',
    passport: '/passport',
    wizard: '/wizard',
    dnd: '/dnd',
    immigration: '/immigration',
    reclaim: '/reclaim',
    arrivalsHall: '/arrivals-hall',
    departureLounge: '/departure-lounge',
    emigration: '/emigration',
    gate: '/gate',
}

export default routes;
