export const PROCESSES = {
    GATE: 'gate',
    IMMIGRATION: 'immigration',
    RECLAIM: 'reclaim',
    CUSTOMS: 'customs',
    ARRIVALS_HALL: 'arrivals hall',
    CHECK_IN: 'check-in',
    SECURITY: 'security',
    DEPARTURE_LOUNGE: 'departure lounge',
    EMIGRATION: 'emigration',
};

export const ARRIVALS = [
    PROCESSES.GATE,
    PROCESSES.IMMIGRATION,
    PROCESSES.RECLAIM,
    PROCESSES.CUSTOMS,
    PROCESSES.ARRIVALS_HALL,
];

export const DEPARTURES = [
    PROCESSES.CHECK_IN,
    PROCESSES.SECURITY,
    PROCESSES.DEPARTURE_LOUNGE,
    PROCESSES.EMIGRATION,
    PROCESSES.GATE,
];

import routes from './routes';

export const getRouteForProcess = (process: string): string => {
    switch (process.toLowerCase()) {
        case PROCESSES.GATE:
            return routes.gate;
        case PROCESSES.IMMIGRATION:
            return routes.immigration;
        case PROCESSES.RECLAIM:
            return routes.reclaim;
        case PROCESSES.CUSTOMS:
            return routes.customs;
        case PROCESSES.ARRIVALS_HALL:
            return routes.arrivalsHall;
        case PROCESSES.CHECK_IN:
            return routes.checkin;
        case PROCESSES.SECURITY:
            return routes.security;
        case PROCESSES.DEPARTURE_LOUNGE:
            return routes.departureLounge;
        case PROCESSES.EMIGRATION:
            return routes.emigration;
        default:
            return routes.home; // Fallback to home if process is not found
    }
};
