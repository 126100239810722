import React from 'react';
import DnDComponent from "./DndComponent.tsx";
import Logo from "../common/Logo.tsx";


const DnDView: React.FC = () => {
    return (
        //TODO @kbaran clean up the hierarchy
        <div className={'dnd-view'}>
            <nav>
                <ul>
                    <li>
                        <Logo/>
                    </li>
                    <li >
                        <a href="/" className='generic-button nav-project-link'> Project 1 - Aeroporto di Bari-Karol Wojtyła </a>
                    </li>

                </ul>
            </nav>

            <div className='dnd-component'>
                <DnDComponent/>
            </div>
        </div>
    );
};

export default DnDView;
