import {useNavigate} from "react-router-dom";
import './AirportOverviewNavigation.css';

export interface TerminalPartData {
    id: number
    name: string;
    position: [number, number, number];
    boxGeo: [number, number, number];
    isHovered: boolean;
    route: string;
}

interface SidebarProps {
    parts: TerminalPartData[];
    onHover: (id: number, isHovered: boolean) => void;
}

const AirportTerminalSidebar = ({parts, onHover}: SidebarProps) => {
    const navigate = useNavigate();

    return (
        <div className="sidebar">
            <h1>
                Project 1 - Aeroporto di Bari-Karol Wojtyła
            </h1>
            <button
                className="add-process-button"
                onClick={() => alert('Add process button clicked')}
                onMouseEnter={(e) => {
                    (e.currentTarget as HTMLButtonElement).style.backgroundColor = 'rgba(80, 80, 80, 0.8)'; // Lighter shade on hover
                }}
                onMouseLeave={(e) => {
                    (e.currentTarget as HTMLButtonElement).style.backgroundColor = 'rgba(0, 0, 0, 0.6)'; // Return to original color
                }}
            >
                <span>
                  +
                </span>
                Add Process
            </button>
            <h2 className="process-list">
                List of processes:
            </h2>
            {parts.map((part) => (
                <div
                    key={part.name}
                    onMouseEnter={() => onHover(part.id, true)}
                    onMouseLeave={() => onHover(part.id, false)}
                    onClick={() => navigate(part.route)}
                    className={`process-item ${part.isHovered ? 'hovered' : ''}`}
                >
                    {part.name}
                </div>
            ))}
        </div>
    );
};

export default AirportTerminalSidebar;