import {NavLink} from "react-router-dom";
import routes from "../../routes.ts";

function Navigation() {
    return (
        <nav>
            <ul>
                <li><NavLink to={routes.airport} className={({isActive}) => (isActive ? 'active' : '')}>Airport
                    overview</NavLink></li>
                <li><NavLink to={routes.security} className={({isActive}) => (isActive ? 'active' : '')}>Security
                    control</NavLink></li>
                <li><NavLink to={routes.passport} className={({isActive}) => (isActive ? 'active' : '')}>Passport
                    control</NavLink></li>
                <li><NavLink to={routes.checkin}
                             className={({isActive}) => (isActive ? 'active' : '')}>Check-in</NavLink></li>
                <li><NavLink to={routes.baggageDropOff}
                             className={({isActive}) => (isActive ? 'active' : '')}>Baggage
                    drop-off</NavLink></li>
                <li><NavLink to={routes.boarding}
                             className={({isActive}) => (isActive ? 'active' : '')}>Boarding</NavLink></li>
                <li><NavLink to={routes.customs}
                             className={({isActive}) => (isActive ? 'active' : '')}>Customs</NavLink></li>
                <li><NavLink to={routes.wizard}
                             className={({isActive}) => (isActive ? 'active' : '')}>Wizard</NavLink></li>
                <li><NavLink to={routes.dnd}
                             className={({isActive}) => (isActive ? 'active' : '')}>DnD playground</NavLink>
                </li>
            </ul>
        </nav>
    )
}

export default Navigation;